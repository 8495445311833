import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_ALL_INFORMATION_REQUEST,
    GET_ALL_INFORMATION_REQUEST_SUCCESS, GET_DASHBOARD_REQUEST, GET_DASHBOARD_REQUEST_SUCCESS
} from "./actionTypes";
import {getDashboardInformation} from "./actions";


function* getAllInformationSaga() {
    try {
        const callBack = yield call(getAllInformationSagaAsync)
        yield put({type: GET_ALL_INFORMATION_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}


const getAllInformationSagaAsync = async () => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/information/all`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getDashboardInformationSaga() {
    try {
        const callBack = yield call(getDashboardInformationSagaAsync)
        yield put({type: GET_DASHBOARD_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}


const getDashboardInformationSagaAsync = async () => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/information/dashboard`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* informationSaga() {
    yield takeEvery(GET_ALL_INFORMATION_REQUEST, getAllInformationSaga)
    yield takeEvery(GET_DASHBOARD_REQUEST, getDashboardInformationSaga)
}

export default informationSaga
