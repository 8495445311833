import {
    GET_FLIGHTS_REQUEST,
    GET_FLIGHT_REQUEST,
    UPDATE_FLIGHT_REQUEST
} from "./actionTypes"

export const getFlights = (data) => ({
    type: GET_FLIGHTS_REQUEST,
    payload :data
})

export const getFlight = (data) => ({
    type: GET_FLIGHT_REQUEST,
    payload :data
})

export const updateFlight = (data) => ({
    type: UPDATE_FLIGHT_REQUEST,
    payload :data
})
