import {
    GET_FLIGHTS_REQUEST_SUCCESS,
    GET_FLIGHT_REQUEST_SUCCESS, UPDATE_FLIGHT_REQUEST_SUCCESS,
    PRELOADER_OFF, PRELOADER_ON, GET_FLIGHTS_REQUEST
} from "./actionTypes"

const INIT_STATE = {
    flights : [],
    flight : {},
    flightsCount : 0,
    preloaderAll : true,
    isLoading: true,
    error: null,
}

const Invoices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRELOADER_ON:
            return {
                ...state,
                preloaderAll : true
            }
        case PRELOADER_OFF:
            return {
                ...state,
                preloaderAll : false
            }

        case GET_FLIGHTS_REQUEST:
            return {
                ...state,
                flights : [],
                flight : {},
                flightsCount : 0,
                preloaderAll : true,
                isLoading: true,
            }
        case GET_FLIGHTS_REQUEST_SUCCESS:
            return {
                ...state,
                flights : action.callBack.flights,
                flightsCount : action.callBack.flightsCount,
                preloaderAll : false,
                isLoading: false,
            }
        case GET_FLIGHT_REQUEST_SUCCESS:
            return {
                ...state,
                flight : action.callBack.flight,
            }
        case UPDATE_FLIGHT_REQUEST_SUCCESS:
         //   state.flights[action.payload.key] = action.payload.flight
            return {
                ...state,
            }

        default:
            return state
    }
}

export default Invoices
