import {
    GET_OFFICE_REQUEST_SUCCESS,
    GET_OFFICES_REQUEST_SUCCESS, UPDATE_OFFICES_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    offices : [],
    office : {}
}

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OFFICES_REQUEST_SUCCESS:
            return {
                ...state,
                offices: action.callBack.offices,
            }

        case GET_OFFICE_REQUEST_SUCCESS:
            return {
                ...state,
                office: action.callBack.office,
            }
        case UPDATE_OFFICES_REQUEST_SUCCESS:
            state.offices[action.payload.key] = action.payload.product
            return {
                ...state,
            }


        default:
            return state
    }
}

export default reducer
