import {CREATE_PAGE_REQUEST, GET_PAGE_REQUEST} from './actionTypes'

export const createPage = (data) =>({
    type : CREATE_PAGE_REQUEST,
    payload : data
})

export const getPageSlug = (data) =>({
    type : GET_PAGE_REQUEST,
    payload : data
})
