import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Col,  Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import ObjectColumns from "./ObjectColumns";
import {getOffices} from "../../store/office/actions";
const Index = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOffices())
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="ოფისი"/>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                products={props.offices.offices}
                            />
                        </Col>
                    </Row>
                </div>
            </div>


        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        offices
    } = state
    return {
        offices
    }
};
export default withRouter(connect(mapStateToProps)(Index));
