import {
    GET_COURIERS_REQUEST,
    GET_COURIER_REQUEST,
    COURIER_FINISH_REQUEST,
    GET_OLD_COURIER_REQUEST,
    UPDATE_COURIER_REQUEST, DELETE_COURIER_REQUEST
} from './actionTypes'


export const getCourier = (data) =>({
    type : GET_COURIER_REQUEST,
    payload : data
})
export const getCouriers = (data) =>({
    type : GET_COURIERS_REQUEST,
    payload : data
})

export const getOldCouriers = (data) =>({
    type : GET_OLD_COURIER_REQUEST,
    payload : data
})


export const courierFinish = (data) =>({
    type : COURIER_FINISH_REQUEST,
    payload : data
})


export const updateCourier = (data) =>({
    type : UPDATE_COURIER_REQUEST,
    payload : data
})


export const deleteCourier = (data) =>({
    type : DELETE_COURIER_REQUEST,
    payload : data
})
