import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Modal, Row, Table} from "reactstrap";

import {notification} from 'antd';


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getTariffs} from "../../store/tariffs/actions";

import ObjectColumns from "./ObjectColumns";
const Index = props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTariffs())
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="ტარიფები"/>
                    <Row>
                        <Col lg={12}>
                           <ObjectColumns
                                products={props.tariffs.tariffs}
                            />
                        </Col>
                    </Row>
                </div>
            </div>


        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        tariffs
    } = state
    return {
        tariffs
    }
};
export default withRouter(connect(mapStateToProps)(Index));
