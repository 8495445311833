import {
  GET_ACCOUNTENT_NULL,
  GET_ACCOUNTENT_REQUEST_SUCCESS,
  GET_INVOICES_ALL_REQUEST_SUCCESS,
  GET_INVOICES_REQUEST_SUCCESS,
  INVOICES_NULL
} from "./actionTypes"

const INIT_STATE = {
  invoice: {},
  invoiceCount: 0,
  invoiceProducts: [],
  invoices: {},
  isLoad : true,
  accountentIsLoad : true,
  accountent : [],
  accountentLength : 0
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_REQUEST_SUCCESS:
      return {
        ...state,
        invoice: action.callBack.invoice,

        invoiceProducts: action.callBack.products,
        isLoad : false
      }
    case INVOICES_NULL:
      return {
        ...state,
        invoice: {},
        invoiceCount: 0,
        invoiceProducts: [],
        invoices: {},
        isLoad : true
      }

    case GET_ACCOUNTENT_NULL:
      return {
        ...state,
        accountent: [],
        isLoad : true
      }

    case GET_INVOICES_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        invoices: action.callBack.invoices,
        invoiceCount: action.callBack.invoiceCount,
        isLoad : false
      }

    case GET_ACCOUNTENT_REQUEST_SUCCESS:
      return {
        ...state,
        accountent: action.callBack.accountent,
        accountentLength: action.callBack.accountentLength,
        accountentIsLoad: false,
      }


    default:
      return state
  }
}

export default Invoices
