import {call, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_PAGE_REQUEST, GET_PAGE_REQUEST, GET_PAGE_REQUEST_SUCCESS
} from "./actionTypes";
import {notification} from 'antd';
import {UPDATE_CHANGE_STATUS_STEP_1_SUCCESS} from "../products/actionTypes";

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};



function* createPageSaga({payload}) {
    try {
        const callBack = yield call(createPageSagaAsync, payload)
        if (callBack.success){
            modalSuccess('success')
        }
    } catch (e) {
        console.error(e);
    }
}

const createPageSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/page/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })
    return await response.json()
}


function* getPageSlugSaga({payload}) {
    try {
        const callBack = yield call(getPageSlugSagaAsync, payload)
        if (callBack.success){
            yield put({type: GET_PAGE_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getPageSlugSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/page/get/${item.slug}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}



function* popapSaga() {
    yield takeEvery(CREATE_PAGE_REQUEST, createPageSaga)
    yield takeEvery(GET_PAGE_REQUEST, getPageSlugSaga)
}

export default popapSaga
