import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Row, Table} from "reactstrap";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Switch from "react-switch"
import {notification} from 'antd';


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getFlights, getNews} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";



const Index = props => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState(1)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100)
    useEffect(() => {
        let item = {
            skip: skip,
            limit: limit,
        }
        dispatch(getNews(item))
    }, [])


    const handlePagination = (page) =>{
        let item = {
            skip: page * limit,
            limit: limit,
        }
        dispatch(getNews(item))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="სიახლეები"/>

                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                pagination={handlePagination}
                                usersArray={props.News.array}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}



const mapStateToProps = state => {
    const {
        News,
    } = state
    return {
        News
    }
};
export default withRouter(connect(mapStateToProps)(Index));
