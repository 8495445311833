import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_COURIER_REQUEST,
    GET_COURIER_REQUEST_SUCCESS,
    GET_COURIERS_REQUEST_SUCCESS,
    COURIER_FINISH_REQUEST,
    COURIER_FINISH_REQUEST_SUCCESS,
    GET_OLD_COURIER_REQUEST,
    UPDATE_COURIER_REQUEST,
    UPDATE_COURIER_REQUEST_SUCCESS,
    DELETE_COURIER_REQUEST, DELETE_COURIER_REQUEST_SUCCESS, GET_COURIERS_REQUEST
} from "./actionTypes";
import {notification} from 'antd';

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getCouriersSaga({payload}) {
    try {
        const callBack = yield call(getCouriersSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_COURIERS_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getCouriersSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/couriers/gets/${item.branch}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}
function* getCourierSaga({payload}) {
    try {
        const callBack = yield call(getCourierSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_COURIER_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getCourierSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/couriers/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getOldCouriersSaga({payload}) {
    try {
        const callBack = yield call(getOldCouriersSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_COURIERS_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getOldCouriersSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/couriers/getsOld/${item.date}/${item.branch}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* courierFinishSaga({payload}) {
    try {
        const callBack = yield call(courierFinishSagaAsync, payload)
        if (callBack.success) {
            modalSuccess('success')
            yield put({type: COURIER_FINISH_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const courierFinishSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/couriers/finish`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* deleteCourierSaga({payload}) {
    try {

        const callBack = yield call(deleteCourierSagaAsync, payload)
        if (callBack.result) {
            modalSuccess('success')
            yield put({type: DELETE_COURIER_REQUEST_SUCCESS, payload})
        }
    } catch (e) {
        console.error(e);
    }
}

const deleteCourierSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/couriers/delete/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* updateCourierSaga({payload}) {
    try {

        const callBack = yield call(updateCourierSagaAsync, payload)
        if (callBack.success) {
            modalSuccess('success')
            yield put({type: UPDATE_COURIER_REQUEST_SUCCESS, payload})
        }
    } catch (e) {
        console.error(e);
    }
}

const updateCourierSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/couriers/update`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })

    return await response.json()
}


function* newsSaga() {
    yield takeEvery(GET_COURIERS_REQUEST, getCouriersSaga)
    yield takeEvery(GET_COURIER_REQUEST, getCourierSaga)
    yield takeEvery(GET_OLD_COURIER_REQUEST, getOldCouriersSaga)
    yield takeEvery(COURIER_FINISH_REQUEST, courierFinishSaga)
    yield takeEvery(UPDATE_COURIER_REQUEST, updateCourierSaga)
    yield takeEvery(DELETE_COURIER_REQUEST, deleteCourierSaga)
}

export default newsSaga
