export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const CHECK_ADMIN_REQUEST = "CHECK_ADMIN_REQUEST"
export const CHECK_ADMIN_REQUEST_SUCCESS = "CHECK_ADMIN_REQUEST_SUCCESS"
export const IS_AUTHENTICAL_STATUS = "IS_AUTHENTICAL_STATUS"
export const CHECK_ADMIN_INFORMATION_REQUEST = "CHECK_ADMIN_INFORMATION_REQUEST"


export const LOGOUT_ADMIN_REQUEST = "LOGOUT_ADMIN_REQUEST"

