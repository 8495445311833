import {call, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_ADMIN_REQUEST,
    DELETE_ADMIN_REQUEST, DELETE_ADMIN_REQUEST_SUCCESS,
    GET_ADMIN_REQUEST, GET_ADMIN_REQUEST_SUCCESS,
} from "./actionTypes";
import {notification} from "antd";

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};


const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getAdminsSaga({payload}) {
    try {
        const callBack = yield call(getAdminsSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_ADMIN_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const getAdminsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/list`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}



function* deleteAdminSaga({payload}) {
    try {
        const callBack = yield call(deleteAdminSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: DELETE_ADMIN_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const deleteAdminSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/delete`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}
function* createAdminSaga({payload}) {
    try {
        const callBack = yield call(createAdminSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: GET_ADMIN_REQUEST})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const createAdminSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}

function* productsSaga() {
    yield takeEvery(GET_ADMIN_REQUEST, getAdminsSaga)
    yield takeEvery(DELETE_ADMIN_REQUEST, deleteAdminSaga)
    yield takeEvery(CREATE_ADMIN_REQUEST, createAdminSaga)
}
export default productsSaga
