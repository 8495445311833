import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_FLIGHTS_REQUEST_SUCCESS,
    GET_FLIGHTS_REQUEST, GET_FLIGHT_REQUEST,
    GET_FLIGHT_REQUEST_SUCCESS,
    UPDATE_FLIGHT_REQUEST,
    UPDATE_FLIGHT_REQUEST_SUCCESS, PRELOADER_ON
} from "./actionTypes";
import {notification} from "antd";


const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};


const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getFlightsSaga({payload}) {
    try {
        yield put({type: PRELOADER_ON})
        const callBack = yield call(getFlightsSagaAsync,payload)
        if (callBack.result){

            yield put({type: GET_FLIGHTS_REQUEST_SUCCESS, callBack})
        }

    } catch (e) {
        console.error(e);
    }
}

const getFlightsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/flight/gets/${item.type}/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
    })
    return await response.json()
}



function* getFlightSaga({payload}) {
    try {
        const callBack = yield call(getFlightSagaAsync, payload)
        yield put({type: GET_FLIGHT_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}

const getFlightSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/flight/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
    })
    return await response.json()
}


function* updateFlightSaga({payload}) {
    try {
        const callBack = yield call(updateFlightSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: UPDATE_FLIGHT_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }

}

const updateFlightSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/flight/update`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}



function* informationSaga() {
    yield takeEvery(GET_FLIGHTS_REQUEST, getFlightsSaga)
    yield takeEvery(GET_FLIGHT_REQUEST, getFlightSaga)
    yield takeEvery(UPDATE_FLIGHT_REQUEST, updateFlightSaga)
}

export default informationSaga
