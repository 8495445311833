import {
    GET_TRANSACTIONS_REQUEST_SUCCESS, GET_USER_TRANSACTIONS_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    userTransactionsArray :[]
}

const Pages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRANSACTIONS_REQUEST_SUCCESS:
            return {
                ...state,
                array :  action.callBack.result,
            }
        case GET_USER_TRANSACTIONS_REQUEST_SUCCESS:
            return {
                ...state,
                array :  action.callBack.result,
            }

        default:
            return state
    }
}

export default Pages
