import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, Container, Row, Spinner,} from "reactstrap";
import {isEmpty} from "lodash";
import {useReactToPrint} from "react-to-print";
import ReactPaginate from 'react-paginate'
import ReactExport from "react-export-excel";
import {accountantNull, getAccountant, getInvoicesAll, getUsers, NullInvoice} from "../../store/actions";


import List from './List'
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import InvoiceSpinner from "../../components/Null/InvoiceSpinner";
import {city} from "../../helpers/status";
import Moment from "react-moment";
import moment from "moment";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Invoice = props => {
    const dispatch = useDispatch();
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(250)

    useEffect(() => {
        dispatch(accountantNull())
        const {flightId} = props.match.params;
        let item = {
            flightId,
            skip,
            limit
        }
        dispatch(getAccountant(item))
    }, [])


    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "AwesomeFileName",
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);


    const handlePageChange = (data) => {
        dispatch(accountantNull())
        const {flightId} = props.match.params;

        let item = {
            flightId,
            skip: data.selected * limit,
            limit: limit,
        }
        dispatch(getAccountant(item))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {
                        props.invoices.accountentIsLoad
                            ? <InvoiceSpinner/>
                            :
                            <>
                                {!isEmpty(props.invoices.accountent) && (
                                    <>
                                        <Row>
                                            <Col xl={12}>
                                                <Card>
                                                    <CardBody>

                                                        <div>
                                                            {loading && <p className="indicator"> Loading...</p>}
                                                            <button
                                                                className="btn btn-success waves-effect waves-light me-1"
                                                                onClick={handlePrint}>
                                                                <i className="fa fa-print"></i> ბეჭვდა
                                                            </button>

                                                            <ExcelFile
                                                                element={<button
                                                                    className='waves-effect waves-light btn btn-primary'>ბუღალტრის
                                                                    EXEL ფაილი</button>}>
                                                                <ExcelSheet data={props.invoices.accountent}
                                                                            name="Employees">
                                                                    <ExcelColumn label="თარიღი"
                                                                                 value={(col) => col.additionalInformation ? `${moment(col.additionalInformation.createdAt).format("YYYY-MM-DD")}` : ""}/>

                                                                    <ExcelColumn label="#"
                                                                                 value={(col) => col.additionalInformation ? `${col.additionalInformation.invoiceUnicId}` : ``}/>

                                                                    <ExcelColumn label="სახელი და გვარი"
                                                                                 value={(col) => parseInt(col.userInformation.userType) === 1
                                                                                     ? `${col.userInformation.nameKa} ${col.userInformation.lastNameKa}`
                                                                                     : `${col.userInformation.nameKa} ${col.userInformation.lastNameKa}`
                                                                                 }/>

                                                                    <ExcelColumn label="პირადი ნომერი"
                                                                                 value={(col) => col.userInformation.passportId}/>
                                                                    <ExcelColumn label="თრექინგ კოდები"
                                                                                 value={(col) => col.productOrderId}/>

                                                                    <ExcelColumn label="წონა"
                                                                                 value={(col) => col.weight}/>

                                                                    <ExcelColumn label="ტარიფი"
                                                                                 value={(col) => col.tariff}/>

                                                                    <ExcelColumn label="კურსი"
                                                                                 value={(col) => col.rate ? col?.rate?.USD : null}/>

                                                                    <ExcelColumn label="ლარი"
                                                                                 value={(col) => col.rate
                                                                                     ? (col.weight * col.tariff * col.rate?.USD).toFixed(2)
                                                                                     : null
                                                                                 }/>


                                                                </ExcelSheet>
                                                            </ExcelFile>


                                                        </div>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="12">
                                                <Card>
                                                    <CardBody>

                                                        <div ref={componentRef} style={{padding: '10px'}}>
                                                            {
                                                                props.invoices.accountent.map((item, key) => {
                                                                    return (
                                                                        <Row key={key}>
                                                                            {
                                                                                item.userInformation.AXID !== 'AX00XA'
                                                                                    ?
                                                                                    <>
                                                                                        <Col>
                                                                                            {
                                                                                                item.additionalInformation
                                                                                                    ?
                                                                                                    <>
                                                                                                        {
                                                                                                            item.additionalInformation.createdAt
                                                                                                                ? moment(item.additionalInformation.createdAt).format("YYYY-MM-DD")
                                                                                                                : null
                                                                                                        }
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </Col>

                                                                                        <Col>
                                                                                            {
                                                                                                item.additionalInformation
                                                                                                    ? item.additionalInformation.invoiceUnicId
                                                                                                    : null
                                                                                            }
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {item.userInformation.nameKa} {' '}
                                                                                            {item.userInformation.lastNameKa}
                                                                                        </Col>

                                                                                        <Col>
                                                                                            {item.userInformation.passportId}
                                                                                        </Col>

                                                                                        <Col>
                                                                                            {item.productOrderId}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {item.weight}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {item.tariff}
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {
                                                                                                item?.rate
                                                                                                    ? item?.rate?.USD
                                                                                                    : null
                                                                                            }
                                                                                        </Col>
                                                                                        <Col>
                                                                                            {
                                                                                                //  (item.weight * item.tariff * item.rate.USD).toFixed(2)
                                                                                            }
                                                                                            {!isEmpty(item.rate)
                                                                                                ?<>
                                                                                                    {!isEmpty(item.rate.USD)
                                                                                                        ? (item.weight * item.tariff * item.rate?.USD).toFixed(2)
                                                                                                        : '--------'}
                                                                                                </>
                                                                                                : '--------'}

                                                                                        </Col>
                                                                                        <hr/>
                                                                                    </>
                                                                                    : null
                                                                            }

                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>


                                    </>
                                )}
                            </>
                    }
                    <ReactPaginate
                        previousLabel={'უკან'}
                        nextLabel={'წინ'}
                        breakLabel={'***'}
                        pageCount={props.invoices.accountentLength / limit}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-link'}
                        nextClassName={'page-link'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}

                    />

                </Container>
            </div>

        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        invoices,
    } = state
    return {
        invoices,
    }
};
export default withRouter(connect(mapStateToProps)(Invoice));
