import {GET_TARIFF_REQUEST, GET_TARIFFS_REQUEST, UPDATE_TARIFFS_REQUEST} from "./actionTypes";


export const getTariffs = () => ({
    type: GET_TARIFFS_REQUEST
})
export const getTariff = (data) => ({
    type: GET_TARIFF_REQUEST,
    payload :data
})

export const updateTariff = (data) => ({
    type: UPDATE_TARIFFS_REQUEST,
    payload :data
})
