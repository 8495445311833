import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {Row, Card, Col, Modal, FormGroup, Label, Button, Badge,} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {deleteNews, deleteUser, getFlight, getUser, updateFlight, updateProduct, updateUser} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";

import Nulled from "../../components/Null/Nulled";


const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [flight, setFlight] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})


    useEffect(() => {
        setFlight(props.flight.flight)
    }, [props.flight.flight._id])


    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)

        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getFlight(item))
        }

        /* */
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }


    const handleChange = e => {
        const {name, value} = e.target;
        setFlight(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setFlight(prevState => ({
            ...prevState,
            information: {...prevState.userInformation, [name]: value}
        }));
    };

    const handleUpdateInformation = () => {
        let item = {
            key,
            flight
        }
        dispatch(updateFlight(item))
        setmodal_xlarge(false)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteNews(item))
        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 10,
        totalSize: 1000, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }

    const Columns = [
        {
            dataField: "newsTitleKa",
            text: "სათაური",
            sort: true,
        },

        {
            dataField: "createdAt",
            text: "შეიქმნა",
            sort: true,
        },

        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <span>
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={() => props.history.push(`/news/detail/${row._id}`)}
                                className="px-3 text-primary">
                                <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                            </span>
                       </span>
                    <span>
                          <FeatherIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                  handleOpenAlertModal(row._id, key)
                              }}
                              icon="trash" className="icon-xs icon me-2 text-danger"/>
                       </span>
                </>
            ),
        },

    ]

    return (
        <>
            {
                !isEmpty(props.usersArray)
                    ?
                    <>
                        <PaginationProvider
                            pagination={paginationFactory(paginationOption)}
                        >
                            {
                                ({
                                     paginationProps,
                                     paginationTableProps
                                 }) => (
                                    <Row>
                                        <Card>
                                            <BootstrapTable
                                                keyField="_id"
                                                data={props.usersArray}
                                                columns={Columns}
                                                {...paginationTableProps}
                                            />

                                        </Card>
                                        {/*   <div className="float-sm-end">
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                        </div>*/}
                                    </Row>
                                )
                            }
                        </PaginationProvider>
                    </>
                    : <Nulled/>
            }

            <Col xl={3} lg={4} sm={6} className="mb-2">

                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}

const mapStateToProps = state => {
    const {
        users,
        flight
    } = state
    return {
        users,
        flight
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));
