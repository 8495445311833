import React, {useEffect} from "react"
import {Card, CardBody, DropdownToggle, DropdownMenu, DropdownItem, Table, UncontrolledDropdown} from "reactstrap"

import FeatherIcon from "feather-icons-react"
//Simple bar
import SimpleBar from "simplebar-react"
import { isEmpty } from "lodash"
//Import Image

import {withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import avatar1 from "../../assets/images/users/avatar-1.jpg"
const TopUserIur = (props) => {

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">ახალი იურიდიული პირები</h4>
                    <SimpleBar style={{maxHeight: "336px"}}>
                        <div className="table-responsive">
                            <Table className="table-borderless table-centered table-nowrap">
                                <tbody>
                                {
                                    !isEmpty(props.information.usersIurArray)
                                        ?
                                        <>
                                            {
                                                props.information.usersIurArray.map((item, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td><img src={avatar1} className="avatar-xs rounded-circle "
                                                                     alt="..."/></td>
                                                            <td>
                                                                <h6 className="font-size-15 mb-1 fw-normal">{item.information.nameKa} {' '} {item.information.lastNameKa}</h6>
                                                                <p className="text-muted font-size-13 mb-0">{item.AXID}</p>
                                                            </td>
                                                            <td><span
                                                                className="badge bg-soft-success font-size-12">Success</span>
                                                            </td>
                                                            <td className="text-muted fw-semibold text-end">
                                                                რამე ინფო?
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                        : null
                                }

                                </tbody>
                            </Table>
                        </div>
                    </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {
        information
    } = state
    return {
        information
    }
};
export default withRouter(connect(mapStateToProps)(TopUserIur));

