import React, {useEffect, useState} from "react"
import {connect,useSelector, useDispatch} from "react-redux";
import {Row, Card, Col, Modal, FormGroup, Label, Button, Badge, InputGroup, Spinner} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {deleteUser, getFlight, getUser, updateFlight, updateProduct, updateUser} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";

import Nulled from "../../components/Null/Nulled";
import Flatpickr from "react-flatpickr";
import Moment from "react-moment";


const ObjectColumns = props => {
    const isLoading = useSelector((state) => state.flight.isLoading);

    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [flight, setFlight] = useState({})
    const [startdAt, setStartdAt] = useState('')
    const [estimatedAt, setEstimatedAt] = useState('')
    const [endAt, setEndAt] = useState('')
    const [key, setKey] = useState({})
    const [id, setId] = useState({})


    useEffect(() => {
        setFlight(props.flight.flight)
        setStartdAt(props.flight.flight.startdAt)
        setEstimatedAt(props.flight.flight.estimatedAt)
        setEndAt(props.flight.flight.endAt)

    }, [props.flight.flight._id])


    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)

        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getFlight(item))
        }
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }


    const handleChange = e => {
        const {name, value} = e.target;
        setFlight(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setFlight(prevState => ({
            ...prevState,
            information: {...prevState.userInformation, [name]: value}
        }));
    };

    const handleUpdateInformation = () => {
        let item = {
            key,
            flight,
            startdAt,
            estimatedAt,
            endAt
        }
        dispatch(updateFlight(item))
        setmodal_xlarge(false)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        //dispatch(deleteUser(item))
        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 10,
        totalSize: props.usersCount - 10, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }

    const Columns = [
        {
            dataField: "flightId",
            text: "რეისი",
            sort: true,
        },
        {
            dataField: "status",
            text: "სტატუსი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    <Badge
                        className={"badge font-size-12 bg-soft-info"}
                        color={'info'}
                        pill
                    >
                        {row.status}
                    </Badge>
                    <br/>
                    <Badge
                        className={"badge font-size-12 bg-soft-info"}
                        color={'info'}
                        pill
                    >
                        წონა : {row.totalInfo.totalWeight.toFixed(2)}
                    </Badge>
                    <br/>
                    <Badge
                        className={"badge font-size-12 bg-soft-info"}
                        color={'info'}
                        pill
                    >
                        რაოდენობა : {row.totalInfo.totalCountity}
                    </Badge>

                </>
            ),
        },
        {
            dataField: "startdAt",
            text: "გამოფრენა",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.startdAt
                            ? <Moment format="YYYY-MM-DD"
                                      date={row.startdAt}/>
                            : null
                    }
                </>
            )
        },
        {
            dataField: "estimatedAt",
            text: "სავარაუდო ჩამოსვლა",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.estimatedAt
                            ? <Moment format="YYYY-MM-DD"
                                      date={row.estimatedAt}/>
                            : null
                    }

                </>
            )
        },
        {
            dataField: "endAt",
            text: "ჩამოსვლა",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.endAt
                            ? <Moment format="YYYY-MM-DD"
                                      date={row.endAt}/>
                            : null
                    }
                </>
            )
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <span>
                        <span>
                                 <Link to={`/logistic/accountant/${row.flightId}`} className="px-3 text-primary">
                                     <FeatherIcon icon="share" className="icon-xs icon me-2 text-pending"/>
                                 </Link>
                        </span>

                         <span>
                                 <Link to={`/logistic/invoiceAll/${row.flightId}`} className="px-3 text-primary">
                                     <FeatherIcon icon="paperclip" className="icon-xs icon me-2 text-pending"/>
                                 </Link>
                        </span>
                       </span>

                    <span>
                               <Link to={`/Excel/${row._id}`}>
                                    <FeatherIcon icon="rss" className="icon-xs icon me-2 text-pending"/>
                               </Link>

                       </span>
                    <span>
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    tog_large(row._id, key)
                                }}
                                className="px-3 text-primary">
                                <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                            </span>

                       </span>
                    <span>
                          <FeatherIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                  handleOpenAlertModal(row._id, key)
                              }}
                              icon="trash" className="icon-xs icon me-2 text-danger"/>
                       </span>
                </>
            ),
        },

    ]
/*    const Pagination = ({ currentPage, totalPages, onPageChange }) => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }

        return (
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => onPageChange(currentPage - 1)} tabIndex="-1" aria-disabled="true">Previous</button>
                    </li>
                    {pageNumbers.map(number => (
                        <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => onPageChange(number)}>{number}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    };*/
    const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const [currentPage, setCurrentPage] = useState(1);

        const handleClick = (pageNumber) => {
            setCurrentPage(pageNumber);
            onPageChange(pageNumber);
        };

        useEffect(()=>{
            setCurrentPage(itemsPerPage)
        },[itemsPerPage])


        const renderPaginationItems = () => {
            const visiblePages = 3; // Количество отображаемых страниц до и после текущей
            const pages = [];

            for (let i = currentPage - visiblePages; i <= currentPage + visiblePages; i++) {
                if (i > 0 && i <= totalPages) {
                    pages.push(i);
                }
            }

            return pages.map((pageNumber) => (
                <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handleClick(pageNumber)}>
                        {pageNumber}
                    </button>
                </li>
            ));
        };

        return (
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => handleClick(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    </li>
                    {renderPaginationItems()}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => handleClick(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        );
    };

    const [currentPage, setCurrentPage] = useState(1);


    const handlePageChange = (pageNumber) => {
        const startIndex = (pageNumber - 1) * 10;
        setCurrentPage(pageNumber)
        props.pagination(startIndex)
    };

    return (
        <>
            {
                isLoading
                    ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                        <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                    </div>
                    :
                    <>
                        {
                            !isEmpty(props.usersArray)
                                ?
                                <>
                                    <Row>
                                        <Card>
                                            <BootstrapTable
                                                keyField="_id"
                                                data={props.usersArray}
                                                columns={Columns}
                                            />

                                        </Card>
                                        <Pagination
                                            totalItems={props.usersCount}
                                            itemsPerPage={currentPage}
                                            onPageChange={handlePageChange}
                                        />

                                    {/*    <Pagination
                                            currentPage={currentPage}
                                            totalPages={10} // Example total number of pages
                                            onPageChange={handlePageChange}
                                        />*/}
                                    </Row>

                                    {/*<PaginationProvider*/}
                                    {/*    pagination={paginationFactory(paginationOption)}*/}
                                    {/*>*/}
                                    {/*    {*/}
                                    {/*        ({*/}
                                    {/*             paginationProps,*/}
                                    {/*             paginationTableProps*/}
                                    {/*         }) => (*/}
                                    {/*            <Row>*/}
                                    {/*                <Card>*/}
                                    {/*                    <BootstrapTable*/}
                                    {/*                        keyField="_id"*/}
                                    {/*                        data={props.usersArray}*/}
                                    {/*                        columns={Columns}*/}
                                    {/*                        {...paginationTableProps}*/}
                                    {/*                    />*/}

                                    {/*                </Card>*/}

                                    {/*                <div className="float-sm-end">*/}
                                    {/*                    <PaginationListStandalone*/}
                                    {/*                        {...paginationProps}*/}
                                    {/*                    />*/}
                                    {/*                </div>*/}
                                    {/*            </Row>*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</PaginationProvider>*/}
                                </>
                                : <Nulled/>
                        }

                    </>
            }

            <Modal size="xl" isOpen={modal_xlarge} toggle={() => {
                tog_large()
            }}>
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        რეისი {props.flight.flight.flightId}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(props.flight.flight.flightId)
                            ?
                            <>

                                <Row>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">სტატუსი </Label>
                                            <select
                                                value={flight.status}
                                                onChange={handleChange}
                                                name="status"
                                                className="form-control">
                                                <option value={'0'}>-----</option>
                                                <option value={'sent'}>sent</option>
                                                <option value={'arrived'}>arrived</option>
                                                <option value={'finished'}>finished</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">შეტყობინება </Label>
                                            <select
                                                value={flight.additionalText}
                                                onChange={handleChange}
                                                name="additionalText"
                                                className="form-control">
                                                <option value={'0'}>-----</option>
                                                <option value={'_THE_FLIGHT_ARRIVED_ON_TIME_'}>რეისი ჩამოვიდა დროულად
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_'}>რეისი ჩამოვიდა</option>
                                                <option value={'_THE_FLIGHT_ARRIVED_1_DAY_EARLIER'}> რეისი ჩამოვიდა 1
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_2_DAY_EARLIER'}> რეისი ჩამოვიდა 2
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_3_DAY_EARLIER'}> რეისი ჩამოვიდა 3
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_4_DAY_EARLIER'}> რეისი ჩამოვიდა 4
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_5_DAY_EARLIER'}> რეისი ჩამოვიდა 5
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_6_DAY_EARLIER'}> რეისი ჩამოვიდა 6
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_7_DAY_EARLIER'}> რეისი ჩამოვიდა 7
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_8_DAY_EARLIER'}> რეისი ჩამოვიდა 8
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_9_DAY_EARLIER'}> რეისი ჩამოვიდა 9
                                                    დღით ადრე
                                                </option>
                                                <option value={'_THE_FLIGHT_ARRIVED_10_DAY_EARLIER'}> რეისი ჩამოვიდა 10
                                                    დღით ადრე
                                                </option>

                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label>რედაქტირებადი </Label>
                                            <select
                                                value={flight.flightProductUpdateStatus}
                                                onChange={handleChange}
                                                name="flightProductUpdateStatus"
                                                className="form-control">
                                                <option value={true}>კი
                                                </option>
                                                <option value={false}>არა
                                                </option>

                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">

                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">გამოფრენის თარიღი</Label>
                                            <InputGroup>
                                                <Flatpickr
                                                    value={startdAt}
                                                    onChange={(e) => setStartdAt(e[0])}
                                                    className="form-control"
                                                    placeholder="dd M,yyyy"
                                                    options={{
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">სავარაუდო თარიღი</Label>
                                            <InputGroup>
                                                <Flatpickr
                                                    value={flight.estimatedAt}
                                                    onChange={(e) => setEstimatedAt(e)}
                                                    className="form-control"
                                                    placeholder="dd M,yyyy"
                                                    options={{
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">ჩამოსვლის თარიღი</Label>
                                            <InputGroup>
                                                <Flatpickr
                                                    value={flight.endAt}
                                                    onChange={(e) => setEndAt(e)}
                                                    className="form-control "
                                                    placeholder="dd M,yyyy"
                                                    options={{
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </div>
            </Modal>
            <Col xl={3} lg={4} sm={6} className="mb-2">

                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}

const mapStateToProps = state => {
    const {
        users,
        flight
    } = state
    return {
        users,
        flight
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));
