import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row, Card, Col, Modal, FormGroup, Label, Badge, Button,
} from "reactstrap"
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {createPrint, deleteProduct, getLogisticProduct, nullProductObject, updateProduct} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Nulled from "../../components/Null/Nulled";
import {shop} from "../../helpers/status";
import ClipLoader from "react-spinners/ClipLoader";

import {branchAddress} from '../../helpers/branch'

const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)

    const [confirm_alert2, setconfirm_alert2] = useState(false)

    const [product, setProduct] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        setId(id)
        setKey(key)
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleOpenAlertModal2 = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert2(true)
    }


    const handleChange = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            userInformation: {...prevState.userInformation, [name]: value}
        }));
    };
    useEffect(() => {
        if (modal_xlarge === true) {
            let item = {
                id: id
            }
            dispatch(getLogisticProduct(item))
            setProduct(props.products.product)
        } else {
            dispatch(nullProductObject())

        }

    }, [modal_xlarge, props.products.product._id])


    const Columns = [
        {
            dataField: "custom",
            text: "გან.",
            formatter: (cellContent, row, key) => (

                <>
                    {
                        row.custom === true
                            ?
                            <>
                                {
                                    row.clearance === true
                                        ? <span style={{color:'red', fontWeight:"strong"}}>მსურს</span>
                                        : <span style={{color:'red', fontWeight:"strong"}}>განს.</span>
                                }
                            </>
                            :
                            <>
                                {
                                    row.clearance === true
                                        ? <span style={{color:'red', fontWeight:"strong"}}>მსურს</span>
                                        : <>---</>
                                }
                            </>
                    }
                </>
            ),
        },
        {
            dataField: "productOrderId",
            text: "თრექინგ კოდი",
            sort: true,
        },
        {
            dataField: "productName",
            text: "დასახელება",
            formatter: (cellContent, row ) => (
                <>
                    {
                        shop(row.productName)
                    }
                </>
            ),
        },
        {
            dataField: "quantity",
            text: "რაოდ.",
            sort: true,
        },
        {
            dataField: "userInformation.nameEn",
            text: "მყიდველი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.userInformation.userType) === 1 || isEmpty(row.userInformation.userType)
                            ? <>
                                {
                                    row.userInformation.nameEn
                                }
                                {' '}
                                {
                                    row.userInformation.lastNameEn
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.userInformation.userType) === 2
                            ? <>
                                {
                                    row.userInformation.indEntrepreneur
                                        ? row.userInformation.nameEn + ' ' +  row.userInformation.lastNameEn
                                        : row.userInformation.LTDNameEn
                                }
                            </>
                            : null
                    }

                </>
            ),
        },
        {
            dataField: "userInformation.AXID",
            text: "AX კოდი",
            sort: true,
        },
        {
            dataField: "userInformation.phone",
            text: "ტელეფონი",
            sort: true,
        },
        {
            dataField: "userInformation",
            text: "პასპორტი/ორგ. ID",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.userInformation.userType) === 1 || isEmpty(row.userInformation.userType)
                            ? <>
                                {
                                    row.userInformation.passportId
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.userInformation.userType) === 2
                            ? <>
                                {
                                    row.userInformation.indEntrepreneur
                                        ? row.userInformation.passportId
                                        : row.userInformation.orgId
                                }
                            </>
                            : null
                    }

                </>
            ),
        },
        {
            dataField: "shop",
            text: "მაღაზია",
            sort: true,
        },
        {
            dataField: "price",
            text: "თანხა",
            sort: true,
        },
        {
            dataField: "currency",
            text: "ვალუტა",
            sort: true,
        },
        {
            dataField: "weight",
            text: "წონა",
            sort: true,
        },

        {
            dataField: "state",
            text: "გადასახდელი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    <Badge
                        className={`badge bg-pill font-size-12 ${row.state === 'unpaid' ? 'bg-soft-warning' : 'bg-soft-success'} `}
                        pill
                    >
                        {
                            row.state === 'paid'
                                ? <> გადახდილია</>
                                : <>გადასახდელი </>
                        }
                    </Badge>
                </>
            ),
        },
        {
            dataField: "file",
            text: "ფაილი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.file
                            ? <a
                                href={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/invoices/${row.file}`}
                                target="_blank"
                                color="success" style={{width: '100%'}}
                                className="btn-sm waves-effect waves-light">
                                ინვოისი
                            </a>
                            : null
                    }
                </>
            ),
        },

        {
            dataField: "branch",
            text: "ფილიალი",
            formatter: (cellContent, row, key) => (
                <strong>
                    {row.branch === "gldani" ? <span style={{color:'red'}}>  {branchAddress(row.branch)}  </span>: null}
                    {row.branch === "didube" ? <span style={{color:'green'}}>  {branchAddress(row.branch)}  </span>: null}
                    {row.branch === "isani" ? <span style={{color:'blue'}}>  {branchAddress(row.branch)}  </span>: null}
                    {row.branch === "kutaisi_1" ? <span style={{color:'blue'}}>  {branchAddress(row.branch)}  </span>: null}
                </strong>
            ),
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.status === 'arrived' || row.status === 'sent' && row.flightId
                            ? <span>
                                 <Link target="_blank" to={`/logistic/invoice/${row.flightId}/${row.userInformation.AXID}/${row.branch}`}
                                       className="px-3 text-primary"><FeatherIcon icon="paperclip" className="icon-xs icon me-2 text-pending"/>
                                 </Link>
                               </span>
                            : null
                    }
                    <span>
                     <span
                         style={{cursor: 'pointer'}}
                         onClick={() => {
                             tog_large(row._id, key)
                         }}
                         className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>

                </span>
                    <span>
                   <FeatherIcon
                       style={{cursor: 'pointer'}}
                       onClick={() => {
                           handleOpenAlertModal(row._id, key)
                       }}
                       icon="trash" className="icon-xs icon me-2 text-danger"/>
                </span>
                </>
            ),
        },

    ]

    const handleUpdateInformation = () => {
        let item = {
            key,
            product
        }
        dispatch(updateProduct(item))
        setmodal_xlarge(false)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteProduct(item))
        setconfirm_alert(false)
    }

    const handlePrint = () => {
        let item = {
            key,
            flightId : id,
            AXID : props.barCode
        }
        dispatch(createPrint(item))
        setconfirm_alert2(false)
    }


    return (
        <>
            {
                !isEmpty(props.logisticProducts)
                    ?
                    <>
                        <Card>
                            {
                                props.logisticProductsFlight3.length > 0
                                    ?
                                    <>
                                        {
                                            props.logisticProductsFlight3.map((item, key) => {
                                                let newArray = []
                                                props.logisticProducts.forEach((element) => {
                                                    if (element.flightId === item.flightId) {
                                                        newArray.push(element)
                                                    }
                                                })
                                                return (
                                                    <div>
                                                        {
                                                            item.flightId
                                                                ?
                                                                <>
                                                                    <h3>{item.flightId}
                                                                        -
                                                                        {
                                                                            item.printStatus
                                                                                ?
                                                                                <Button
                                                                                    type="button"
                                                                                    color="success"
                                                                                    className="btn btn-success w-sx waves-effect waves-light"
                                                                                    // onClick={handleChangeStatusChecked}
                                                                                >
                                                                                    დაბეჭდილია
                                                                                </Button>
                                                                                :   <Button
                                                                                    type="button"
                                                                                    color="success"
                                                                                    className="btn btn-danger w-sx waves-effect waves-light"
                                                                                    onClick={() => {
                                                                                        handleOpenAlertModal2(item.flightId, key)
                                                                                    }}
                                                                                    // onClick={handleChangeStatusChecked}
                                                                                >
                                                                                    დასაბეჭდია
                                                                                </Button>
                                                                        }

                                                                    </h3>
                                                                    <BootstrapTable
                                                                        selectRow={{
                                                                            mode: 'checkbox', clickToSelect: false,
                                                                            onSelect: props.onRowSelect,
                                                                            onSelectAll: props.onSelectAll,
                                                                            flightId: item.flightId,
                                                                        }}
                                                                        _id={'_id'}
                                                                        keyField="_id"
                                                                        data={newArray}
                                                                        columns={Columns}
                                                                    />

                                                                </>
                                                                :null
                                                        }

                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    : null
                            }
                        </Card>
                    </>
                    : <Nulled/>
            }

            <Modal size="xl" isOpen={modal_xlarge} toggle={() => {
                tog_large()
            }}>
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        თრექინგ კოდი: {product.productOrderId}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(product._id)
                            ?
                            <>
                                <Row>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">თრექინგ კოდი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.productOrderId}
                                                onChange={handleChange}
                                                name="productOrderId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">დასახელება</Label>
                                            <select
                                                value={product.productName}
                                                onChange={handleChange}
                                                name="productName"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="_VARIOUS_ELECTRONIC_DEVICES_">სხვადასხვა ელექტრონული
                                                    მოწყობილებები
                                                </option>
                                                <option value="_BAGS_AND_HOLDERS">ჩანთები და ჩასადებები</option>
                                                <option value="_SHOES_">ფეხსაცმელი</option>
                                                <option value="_TELEPHONE_AND_NETWORK_DEVICES">ტელეფონი და ქსელური
                                                    მოწყობილობები
                                                </option>
                                                <option value="_CLOTHING_ALL_TYPES_OF_CLOTHING_">ტანსაცმელი, ყველა ტიპის
                                                    სამოსი
                                                </option>
                                                <option value="_FOOD_SUPPLEMENTS_">საკვები დანამატები</option>
                                                <option value="_TOYS_AND_SPORTS_EQUIPMENT_">სათამაშოები და სპორტული
                                                    ინვენტარი
                                                </option>
                                                <option value="_WATCHES_">საათები</option>
                                                <option value="_PERFUMERY_AND_COSMETICS_">პარფიუმერია და კოსმეტიკა
                                                </option>
                                                <option value="_PRINTED_PRODUCTS_BOOKS_BROCHURES_">ნაბეჭდი პროდუქცია,
                                                    წიგნები, ბროშურები
                                                </option>
                                                <option value="_MUSICAL_INSTRUMENTS_AND_THEIR_PARTS_">მუსიკალური
                                                    ინსტრუმენტები და მათი ნაწილები
                                                </option>
                                                <option value="_GLASSWARE_">მინის ნაწარმი</option>
                                                <option value="_MEDICATIONS_">მედიკამენტები</option>
                                                <option value="_COMPUTER_LAPTOP_AND_THEIR_PARTS_">კომპიუტერი, ლეპტოპი და
                                                    მათი ნაწილები
                                                </option>
                                                <option value="_TOOLS_AND_HAND_TOOLS_">იარაღები და ხელის ინსტრუმენტები
                                                </option>
                                                <option value="_LIGHTING_CHANDELIERS_LAMPS_HEADLIGHTS_">განათება,
                                                    ჭაღები, ლამპები, ფარები
                                                </option>
                                                <option value="_BIJOUTERIE_">ბიჟუტერია</option>
                                                <option value="_AUTO_PARTS_">ავტო ნაწილები</option>
                                                <option value="_VARIOUS_FINISHED_PRODUCTS_">სხვადასხვა მზა ნაწარმი
                                                </option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">რაოდ</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.quantity}
                                                onChange={handleChange}
                                                name="quantity"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მყიდველის სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                disabled
                                                value={product.userInformation.nameEn}
                                                name="nameEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მყიდველი გვარი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.lastNameEn}
                                                disabled
                                                name="lastNameEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        parseInt(product.userInformation.userType) === 2
                                            ?
                                            <>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">ორგანიზაციის სახელი</Label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.userInformation.LTDNameEn}
                                                            disabled
                                                            name="LTDNameEn"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">ორგ Id</Label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={product.userInformation.orgId}
                                                            disabled
                                                            name="orgId"
                                                        />
                                                    </FormGroup>
                                                </Col>


                                            </>
                                            : null
                                    }

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">AXID</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.AXID}
                                                onChange={handleChangeInformation}
                                                name="AXID"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.phone}
                                                onChange={handleChangeInformation}
                                                name="phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">პასპორტი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.passportId}
                                                onChange={handleChangeInformation}
                                                name="passportId"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მაღაზია</Label>
                                            <select
                                                value={product.shop}
                                                onChange={handleChange}
                                                name="shop"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="TAOBAO.COM">TAOBAO.COM</option>
                                                <option value="EBAY.COM">EBAY.COM</option>
                                                <option value="ALIEXPRESS.COM">ALIEXPRESS.COM</option>
                                                <option value="ALIBABA.COM">ALIBABA.COM</option>
                                                <option value="TMALL.COM">TMALL.COM</option>
                                                <option value="other">სხვა მაღაზია</option>
                                            </select>

                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">თანხა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.price}
                                                onChange={handleChange}
                                                name="price"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">Currency</Label>
                                            <select
                                                value={product.currency}
                                                onChange={handleChange}
                                                name="currency"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="CNY">CNY</option>
                                                <option value="TRY">TRY</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">წონა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.weight}
                                                onChange={handleChange}
                                                name="weight"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">რეისი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.flightId}
                                                onChange={handleChange}
                                                name="flightId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">სტატუსი </Label>
                                            <select
                                                value={product.status}
                                                onChange={handleChange}
                                                name="status"
                                                className="form-control">
                                                <option value='waiting'>waiting</option>
                                                <option value='stock'>stock</option>
                                                <option value='sent'>sent</option>
                                                <option value='arrived'>arrived</option>
                                                <option value='obtain'>obtain</option>
                                                <option value='lost'>lost</option>
                                                <option value='other'>other</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    {
                                        props.auth.admin.role === 1 &&<Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">გადახდა  </Label>
                                                <select
                                                    value={product.state}
                                                    onChange={handleChange}
                                                    name="state"
                                                    className="form-control">
                                                    <option value='unpaid'>არა გადახდილი</option>
                                                    <option value='paid'>გადახდილია</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </div>
            </Modal>
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>

            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert2 ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handlePrint}
                        onCancel={() => setconfirm_alert2(false)}
                    >
                        რომ გსურთ დაადასტუროთ?!
                    </SweetAlert>
                ) : null}
            </Col>

        </>
    )
}

const mapStateToProps = state => {
    const {
        auth,
        products,
    } = state
    return {
        auth,
        products,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));
