import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_NEW_REQUEST,
    GET_NEWS_REQUEST, GET_NEWS_REQUEST_SUCCESS,
    GET_NEW_REQUEST_SUCCESS, DELETE_NEWS_REQUEST, DELETE_NEWS_REQUEST_SUCCESS
} from "./actionTypes";
import {notification} from 'antd';

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getNewsSaga({payload}) {
    try {
        const callBack = yield call(getNewsSagaAsync, payload)
        if (callBack.success){
            yield put({type: GET_NEWS_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getNewsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/news/list/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getNewSaga({payload}) {
    try {
        const callBack = yield call(getNewSagaAsync, payload)
        if (callBack.success){
            yield put({type: GET_NEW_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getNewSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/news/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* deleteNewsSaga({payload}) {
    try {
        const callBack = yield call(deleteNewsSagaAsync, payload)
        if (callBack.result){
            yield put({type: DELETE_NEWS_REQUEST_SUCCESS, payload})
        }
    } catch (e) {
        console.error(e);
    }
}

const deleteNewsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/news/delete/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}



function* newsSaga() {
    yield takeEvery(GET_NEWS_REQUEST, getNewsSaga)
    yield takeEvery(GET_NEW_REQUEST, getNewSaga)
    yield takeEvery(DELETE_NEWS_REQUEST, deleteNewsSaga)
}

export default newsSaga
