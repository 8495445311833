import React, {useEffect, useState} from "react"
import {Card, CardBody, Container} from "reactstrap"
import {Button, Col, Row,} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {connect, useDispatch} from "react-redux";
import {geDebtSum, getGiveaway, getLogisticProductByTrackingId, nullProductObject} from "../../store/products/actions";
import {withRouter} from "react-router-dom";
import GiveawayObjectColumns from "./GiveawayObjectColumns";
import ClipLoader from "react-spinners/ClipLoader";
import {changeStatusStep2Cheked, changeStatusStep6Cheked, paymentChecked} from "../../store/logistic/actions";
import ObjectColumns from "./ObjectColumns";
import {notification} from "antd";
import {isEmpty} from "lodash";

const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};



const Giveaway = (props) => {
    const dispatch = useDispatch();
    const [barCode, setBarCode] = useState()
    const [barCodeHide, setBarCodeHide] = useState()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(1000)

    const handleBarCode = (e) => {
        dispatch(nullProductObject())

        if (barCode) {
            if (barCode !== barCodeHide) {
                setUniqArray([])
                setSelectProducts([])
            }
            setBarCodeHide(barCode)
            let item = {
                barCode: barCode
            }
            dispatch(geDebtSum(item))
            dispatch(getGiveaway(item))
        }
    }
    useEffect(() => {
        dispatch(nullProductObject())
    }, [])

    useEffect(() => {
        if (barCode) {
            let item = {
                barCode: barCode
            }
            dispatch(geDebtSum(item))
            dispatch(getGiveaway(item))
        }
    }, [props.products.giveawayProducts.length])


    const [uniqArray, setUniqArray] = useState([])
    const [selectProducts, setSelectProducts] = useState([])


    const onSelectAll = (isSelected, a) => {
        if (isSelected) {
            a.map((item) => {
                setSelectProducts(prevArray =>
                    [...prevArray, item._id]
                )
            })
        } else {
            setSelectProducts([])
        }
    }

    function onRowSelect(row, isSelected) {
        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dispatch(nullProductObject())

            if (barCode) {
                if (barCode !== barCodeHide) {
                    setUniqArray([])
                    setSelectProducts([])
                }
                setBarCodeHide(barCode)
                let item = {
                    barCode: barCode
                }
                dispatch(geDebtSum(item))
                dispatch(getGiveaway(item))
            }

        }
    }

    const handleChangeStatusChecked = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setUniqArray(uniqArray)
        if (uniArray.length === 0) {
            modalWarning('warning');
        } else {
            let item = {
                barCode: barCode,
                ids: uniArray,
                skip: skip,
                limit: limit,
            }
            dispatch(changeStatusStep6Cheked(item))
        }
    }
    const handlePaymentChecked = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setUniqArray(uniqArray)
        if (uniArray.length === 0) {
            modalWarning('warning');
        } else {
            let item = {
                barCode: barCode,
                ids: uniArray,
                skip: skip,
                limit: limit,
            }
            dispatch(paymentChecked(item))
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="გაცემა"/>
                    <Row>
                        <Col md={8}>
                            <input
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setBarCode(e.target.value)}
                                className="form-control" type="text" name="code"
                            />
                        </Col>
                        <Col md={4}>
                            <Button
                                type="button"
                                color="primary"
                                className="btn btn-primary w-xs waves-effect waves-light"
                                // disabled={props.logistic.preloaderAll}
                                onClick={handleBarCode}
                            >

                                <span>  Barcode it!  </span>
                            </Button>{" "}
                        </Col>
                    </Row>
                    {
                        props.products.giveawayProductsCount
                            ?
                            <Row>
                                <Col xl={12}>
                                    <br/>
                                    <Card>
                                        <CardBody>
                                            <div className="button-items" style={{float: 'left'}}>
                                                <Button
                                                    type="button"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                    onClick={handleChangeStatusChecked}
                                                >
                                                    {
                                                        props.logistic.preloaderChecked === true
                                                            ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                            : null
                                                    }
                                                    <span> გატანა </span>
                                                </Button>

                                                <Button
                                                    type="button"
                                                    color="warning"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                    onClick={handlePaymentChecked}
                                                >
                                                    <span> გადახდა </span>
                                                </Button>
                                            </div>
                                            <div style={{float: 'right'}}>
                                                <h3 style={{color: 'blue'}}>
                                                    ბალანსი: <strong>
                                                    {
                                                        props.products.giveawayBalance.toFixed(2)
                                                    }
                                                    {}</strong> ₾.
                                                </h3>
                                                <h3 style={{color: '#ff033e'}}>
                                                    დავალიანება : <strong>
                                                    {
                                                        props.products.giveawayDeptSum !== null
                                                            ? props.products.giveawayDeptSum.toFixed(2)
                                                            : <>0</>
                                                    }
                                                     </strong> ₾.
                                                </h3>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card>

                                    </Card>
                                </Col>
                            </Row>
                            : null
                    }

                    <Row>
                        <Col lg={12}><br/>
                            <GiveawayObjectColumns
                                onRowSelect={onRowSelect}
                                onSelectAll={onSelectAll}
                                barCode={barCode}
                                logisticProducts={props.products.giveawayProducts}
                                logisticProductsFlight={props.products.giveawayFlight}
                                logisticProductsFlight3={props.products.giveawayFlight3}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Giveaway));

