import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"

//E-commerce

//chat
import chat from "./chat/reducer"

//invoices


//contacts


//
import auth from "./auth/reducer"
import information from "./information/reducer"
import products from "./products/reducer"
import logistic from "./logistic/reducer"
import flight from "./flight/reducer"
import users from "./user/reducer"
import admin from "./admin/reducer"
import invoices from "./invoices/reducer"
import tariffs from "./tariffs/reducer"
import offices from "./office/reducer"
import pages from "./page/reducer"
import News from "./news/reducer"
import Courier from "./courier/reducer"
import Merchant from "./merchant/reducer"
import Popap from "./popap/reducer"


const rootReducer = combineReducers({
  // public
  admin,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,



  auth,
  information,
  products,
  logistic,
  flight,
  users,
  invoices,
  tariffs,
  offices,
  pages,
  News,
  Courier,
  Merchant,
  Popap
})

export default rootReducer
