import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";

import {notification} from 'antd';


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {createPopapNotification, getPopap, updatePopapStatus} from "../../store/popap/actions";
import Popap from "../../store/popap/reducer";

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'შეავსეთ ყველა ველი',
    });
};

const Create = props => {
    const dispatch = useDispatch();
    const [popapStatus, setPopapStatus] = useState('');
    const [titleKa, setTitleKa] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleRu, setTitleRu] = useState('');
    const [descriptionKa, setDescriptionKa] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionRu, setDescriptionRu] = useState('');

    useEffect(()=>{
        dispatch(getPopap())

        if(props.Popap.result._id) {
            setPopapStatus(props.Popap.result.status || '')

            setTitleKa(props.Popap.result.titleKa || '')
            setTitleEn(props.Popap.result.titleEn || '')
            setTitleRu(props.Popap.result.titleRu || '')
            setDescriptionKa(props.Popap.result.descriptionKa || '')
            setDescriptionEn(props.Popap.result.descriptionEn || '')
            setDescriptionRu(props.Popap.result.descriptionRu || '')
        }
    },[props.Popap.result._id])

    const handleAddPopap = (e) => {
        let item = {
            titleKa,
            titleEn,
            titleRu,
            descriptionKa,
            descriptionEn,
            descriptionRu
        }

        if (titleKa && titleEn && titleRu && descriptionKa && descriptionEn && descriptionRu) {
            setTitleKa('')
            setTitleEn('')
            setTitleRu('')
            setDescriptionKa('')
            setDescriptionEn('')
            setDescriptionRu('')
            dispatch(createPopapNotification(item))
        } else {
            modalError('error')
        }
    }
    const handleUpdatePopapStatus = (e) => {
        let item = {
            popapStatus
        }

        dispatch(updatePopapStatus(item))

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="" breadcrumbItem="პოპაპის დამატება/მართვა"/>
                    <Card>
                        <CardBody>
                            <div className="button-items">
                                <Row>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">პოპაპის სტატუსი</Label>
                                            <select
                                                value={popapStatus}
                                                onChange={(e) => setPopapStatus(e.target.value)}
                                                name="productName"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="0">გამორთვა</option>
                                                <option value="1">ჩართვა</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup className="mb-3">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn btn-primary w-xs waves-effect waves-light"
                                                onClick={handleUpdatePopapStatus}
                                            >
                                                <span>დამატება</span>
                                            </Button>
                                        </FormGroup>

                                    </Col>
                                </Row>


                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <div className="button-items">

                                <Row style={{paddingBottom: '20px'}}>
                                    <Col md={4}>
                                        <Label>სათაური ქართულად</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={titleKa}
                                            onChange={(e) => setTitleKa(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>სათაური ინგლისურად</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={titleEn}
                                            onChange={(e) => setTitleEn(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>სათაური რუსულად</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={titleRu}
                                            onChange={(e) => setTitleRu(e.target.value)}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{paddingBottom: '20px'}}>
                                    <Col md={4}>
                                        <Label>პოსტი ქართულად</Label>
                                        <Input
                                            type="textarea"
                                            style={{height: '220px'}}
                                            value={descriptionKa}
                                            onChange={(e) => setDescriptionKa(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>პოსტი ინგლისურად</Label>
                                        <Input
                                            type="textarea"
                                            style={{height: '220px'}}
                                            value={descriptionEn}
                                            onChange={(e) => setDescriptionEn(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>პოსტი რუსულად</Label>
                                        <Input
                                            type="textarea"
                                            style={{height: '220px'}}
                                            value={descriptionRu}
                                            onChange={(e) => setDescriptionRu(e.target.value)}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{paddingBottom: '20px'}}>
                                    <Col md={4}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn btn-primary w-xs waves-effect waves-light"
                                            onClick={handleAddPopap}
                                        >
                                            <span>დამატება</span>
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>


        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        Popap
    } = state
    return {
        Popap
    }
};
export default withRouter(connect(mapStateToProps)(Create));
