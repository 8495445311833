export const GET_USERS_REQUEST = "GET_USERS_REQUEST"
export const GET_USERS_REQUEST_SUCCESS = "GET_USERS_REQUEST_SUCCESS"

export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_REQUEST_SUCCESS = "GET_USER_REQUEST_SUCCESS"

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_REQUEST_SUCCESS = "UPDATE_USER_REQUEST_SUCCESS"
export const UPDATE_USER_BALANCE_REQUEST = "UPDATE_USER_BALANCE_REQUEST"
export const UPDATE_USER_BALANCE_REQUEST_SUCCESS = "UPDATE_USER_BALANCE_REQUEST_SUCCESS"
export const UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST = "UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST"
export const UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST_SUCCESS = "UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST_SUCCESS"
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST"
export const DELETE_USER_REQUEST_SUCCESS = "DELETE_USER_REQUEST_SUCCESS"
export const SEARCH_USER_REQUEST = "SEARCH_USER_REQUEST"
export const SEARCH_USER_REQUEST_SUCCESS = "SEARCH_USER_REQUEST_SUCCESS"

