import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import { Col, Row, } from "reactstrap";



import Breadcrumbs from "../../components/Common/Breadcrumb";
import {  getFlightsProducts} from "../../store/actions";

import ObjectColumnsExcel from "./ObjectColumnsExcel";

const Excel = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        const {id} = props.match.params;
        let item = {
            id,
            status: 'all'
        }
        dispatch(getFlightsProducts(item))
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="რეისი excel"/>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumnsExcel
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                logisticProducts={props.products.products}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}



const mapStateToProps = state => {
    const {
        flight,
        products
    } = state
    return {
        flight,
        products
    }
};
export default withRouter(connect(mapStateToProps)(Excel));
