import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row, Card, Col, Modal, FormGroup, Label, CardBody, Button,
} from "reactstrap"
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {  getTariff, updateTariff} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Nulled from "../../components/Null/Nulled";

import {tariffsType, countries} from '../../helpers/status'
const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [product, setProduct] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            setId(id)
            dispatch(getTariff(item))
        }
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        setProduct(props.tariffs.tariff)
    }, [props.tariffs.tariff._id])

    const Columns = [
        {
            dataField: "type",
            text: "დასახელება",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {
                        tariffsType(cellContent)

                    }
                </>
            ),
        },

        {
            dataField: "country",
            text: "ქვეყანა",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {
                        countries(cellContent)
                    }

                </>
            ),
        },
        {
            dataField: "amount",
            text: "ფასი",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {
                        cellContent
                    }
                    {' '}
                    {
                        row.currency
                    }
                </>
            ),
        },


        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                  <span
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                          tog_large(row._id, key)
                      }}
                      className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>
                </>
            ),
        },

    ]

    const handleUpdateInformation = () => {
        let item = {
            key,
            id,
            product
        }
        dispatch(updateTariff(item))
        setmodal_xlarge(false)
    }


    return (
        <>
            {
                !isEmpty(props.products)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                _id={'_id'}
                                keyField="_id"
                                data={props.products}
                                columns={Columns}
                            />

                        </Card>

                    </>
                    : <Nulled/>
            }

            <Modal size="xl" isOpen={modal_xlarge}
                   toggle={() => {
                       tog_large()
                   }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        {
                            tariffsType(product.type)
                        }
                        {', '}
                        {
                            countries(product.country)
                        }
                        {', '}
                        {
                            product.currency
                        },
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(product._id)
                            ?
                            <>
                                <Row>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">ტარიფის რედაქტირება</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.amount}
                                                onChange={handleChange}
                                                name="amount"
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </div>
            </Modal>

        </>
    )
}

const mapStateToProps = state => {
    const {
        tariffs,
    } = state
    return {
        tariffs,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));

