import {call, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_NOTIFICATION_REQUEST,
    CREATE_NOTIFICATION_REQUEST_SUCCESS
} from "./actionTypes";
import {notification} from 'antd';

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};



function* createPopapSaga({payload}) {
    try {
        const callBack = yield call(createPopapSagaAsync, payload)

        if (callBack.success){
            modalSuccess('success')
        }
    } catch (e) {
        console.error(e);
    }
}

const createPopapSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/notification/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })
    return await response.json()
}



function* popapSaga() {
    yield takeEvery(CREATE_NOTIFICATION_REQUEST, createPopapSaga)
}

export default popapSaga
