import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import {Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {isEmpty} from "lodash";
import {useReactToPrint} from "react-to-print";
import {ComponentToPrint} from "./ComponentToPrint";
import {getInvoices} from "../../store/actions";
import logo from "../../assets/images/logo.png";
import Nulled from "../../components/Null/Nulled";


const Invoice = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        const {flightId, AXID, branch} = props.match.params;
        let item = {
            flightId,
            AXID,
            branch
        }
        dispatch(getInvoices(item))
    }, [props.invoices.invoice._id])


    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoading, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "AwesomeFileName",
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);


    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    {!isEmpty(props.invoices.invoice._id) && (
                        <Row>
                            <div>
                                {loading && <p className="indicator">onBeforeGetContent: Loading...</p>}
                                <button
                                    className="btn btn-success waves-effect waves-light me-1"
                                    onClick={handlePrint}>
                                    <i className="fa fa-print"></i> ბეჭდვა
                                </button>

                            </div>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div ref={componentRef} style={{padding: '10px'}}>
                                            <div className="invoice-title">
                                                <h4 className="float-end font-size-16">

                                                </h4>
                                                <div className="mb-4">
                                                    <img src={logo} alt="logo" height="60"/>
                                                </div>
                                            </div>
                                            <hr className="my-4"/>
                                            <Row>
                                                <Col sm="6">
                                                    <div className="text-muted">
                                                        <p className="mb-1">შპს ალიჯორჯია:</p>
                                                        <p className="mb-1">ს/კ: 402011568</p>
                                                        <p className="mb-1">ფაქტობრივი მისამართი</p>
                                                        <p className="mb-1">ქ. თბილისი არჩილ ქურდიანის 12/14</p>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="text-muted text-sm-end">
                                                        <div>
                                                            <p className="mb-1">თარიღი: <span>
                                                             <Moment format="YYYY-MM-DD"
                                                                     date={props.invoices.invoice.createdAt}/>
                                                        </span></p>

                                                        </div>
                                                        <div>
                                                            <p className="mb-1">ნომერი: <span>{props.invoices.invoice.invoiceUnicId}</span>
                                                            </p>

                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr className="my-4"/>
                                            <Row>
                                                <Col>
                                                    <div className="text-muted">
                                                        <p className="mb-1">სს. "საქართველოს ბანკი"</p>
                                                        <p className="mb-1">ანგ: № GE75BG0000000917631100</p>
                                                        <p className="mb-1">ბანკის კოდი 220101502 / BAGAGE22 /</p>
                                                        <p className="mb-1">დანიშნულება</p>
                                                        <p>ტრანსპორტირების საფასური</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr className="my-4"/>
                                            <Row>
                                                <Col>
                                                    <div className="text-muted">
                                                        <h5 className="font-size-15"><strong>მიმღები</strong></h5>
                                                        {
                                                            isEmpty(props.invoices.invoice?.additionalInformation?.user?.type) &&
                                                                <>
                                                                    {
                                                                        parseInt(props.invoices.invoice.additionalInformation.user.type) === 1
                                                                            ? <>
                                                                                {
                                                                                    props.invoices.invoice.additionalInformation.user.nonGeorgianCitizen
                                                                                        ?
                                                                                        <>
                                                                                            <p className="mb-1">სახელი,
                                                                                                გვარი: <span>{props.invoices.invoice.additionalInformation.user.information.nameEn} {' '} {props.invoices.invoice.additionalInformation.user.information.lastNameEn}</span>
                                                                                            </p>
                                                                                            <p className="mb-1">პირადი
                                                                                                ნომერი: <span>{props.invoices.invoice.additionalInformation.user.information.passportId}</span>
                                                                                            </p>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <p className="mb-1">სახელი,
                                                                                                გვარი: <span>{props.invoices.invoice.additionalInformation.user.information.nameKa} {' '} {props.invoices.invoice.additionalInformation.user.information.lastNameKa}</span>
                                                                                            </p>
                                                                                            <p className="mb-1">პირადი
                                                                                                ნომერი: <span>{props.invoices.invoice.additionalInformation.user.information.passportId}</span>
                                                                                            </p>
                                                                                        </>
                                                                                }

                                                                            </>
                                                                            : null
                                                                    }
                                                                    {
                                                                        parseInt(props.invoices.invoice.additionalInformation.user.type) === 2
                                                                            ? <>
                                                                                {
                                                                                    props.invoices.invoice.additionalInformation.user.information.indEntrepreneur
                                                                                        ?
                                                                                        <>
                                                                                            <p className="mb-1">სახელი,
                                                                                                გვარი: <span>{props.invoices.invoice.additionalInformation.user.information.nameKa} {' '} {props.invoices.invoice.additionalInformation.user.information.lastNameKa}</span>
                                                                                            </p>
                                                                                            <p className="mb-1">პირადი
                                                                                                ნომერი: <span>{props.invoices.invoice.additionalInformation.user.information.passportId}</span>
                                                                                            </p>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <p className="mb-1"> ორგანიზაცის სახელი: <span>{props.invoices.invoice.additionalInformation.user.information.LTDName}  </span>
                                                                                            </p>
                                                                                            <p className="mb-1">ორგ. ID: <span>{props.invoices.invoice.additionalInformation.user.information.orgId}</span>
                                                                                            </p>
                                                                                        </>
                                                                                }

                                                                            </>
                                                                            : null
                                                                    }

                                                                </>
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <div className="table-responsive">
                                                <Table className="table-nowrap table-centered mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: "70px"}}>გზავნილის ნომერი</th>
                                                        <th>რაოდენობა</th>
                                                        <th>წონა (კგ)</th>
                                                        <th>ტარიფი (USD)</th>
                                                        <th>ეროვ. ბანკის კურსი</th>
                                                        <th>თანხა (ლ.)</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        props.invoices.invoiceProducts.map((item, key) => {

                                                            return (
                                                                <tr key={key}>
                                                                    <td>{item.productOrderId}</td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>{item.weight}</td>
                                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>{item.tariff}</td>


                                                                    {
                                                                        !isEmpty(item.rate)
                                                                            ?
                                                                            <>
                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}>{item.rate.USD}</td>
                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}>{(item.weight * item.tariff * item.rate.USD).toFixed(2)}</td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                                <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                                            </>
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td><strong>სულ</strong></td>
                                                        <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                            <strong>{props.invoices.invoice.additionalInformation.allQuantity}</strong>
                                                        </td>
                                                        <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                            <strong>{props.invoices.invoice.additionalInformation.allWeight.toFixed(2)}</strong>
                                                        </td>
                                                        <td style={{borderLeft: '1px solid #f5f6f8'}}>{}</td>
                                                        <td style={{borderLeft: '1px solid #f5f6f8'}}>{}</td>
                                                        {
                                                            !isEmpty(props.invoices.invoice.additionalInformation.allSum) || props.invoices.invoice.additionalInformation.allSum > 0
                                                                ? <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                                    <strong>{props.invoices.invoice.additionalInformation.allSum.toFixed(2)}</strong>
                                                                </td>
                                                                : <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                        }
                                                    </tr>

                                                    </tbody>
                                                </Table>
                                            </div>
                                            <hr className="my-4"/>


                                            <Row>
                                                <Col>
                                                    <div className="text-muted">
                                                        <h5 className="font-size-15"><strong>კონტაქტი:</strong></h5>
                                                        <p className="mb-1">ვებ გვერდი: <span>www.aligeorgia.ge</span>
                                                        </p>
                                                        <p className="mb-1">ელ.ფოსტა: <span>info.aligeorgia@gmail.com</span>
                                                        </p>
                                                        <p className="mb-1">ტელეფონი: <span>(+032) 2 196 191</span></p>
                                                        <p className="mb-1">გმადლობთ, რომ სარგებლობთ ჩვენი
                                                            მომსახურებით!</p>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {isEmpty(props.invoices.invoice._id) && (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Nulled/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )
                    }
                </Container>
            </div>

        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        invoices,

    } = state
    return {
        invoices,
    }
};
export default withRouter(connect(mapStateToProps)(Invoice));
