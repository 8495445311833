import React, {useState, Component} from "react"
import {Button, Card, CardBody,  Col, Container,  Row, Label, Input} from "reactstrap"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {createPage, getPageSlug} from "../../store/page/actions";
import {useDispatch} from "react-redux";
import pages from "../../store/page/reducer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

class Terms extends Component {

    constructor(props) {

        super(props);
        this.state = {
            // html : '<p>Test <strong>ge edito!!r</strong>  😀</p>',
            html : '',
            html2 : '',
            html3 : '',
            titleKa : '',
            slug : '',
            _id : '',
            titleEn : '',
            titleRu : '',
            editorState : '',
            editorState2 : '',
            editorState3 : '',
        };
        const contentBlock = htmlToDraft(this.state.html);
        const contentBlock2 = htmlToDraft(this.state.html2);
        const contentBlock3 = htmlToDraft(this.state.html3);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const contentState2 = ContentState.createFromBlockArray(contentBlock2.contentBlocks);
            const contentState3 = ContentState.createFromBlockArray(contentBlock3.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            const editorState2 = EditorState.createWithContent(contentState2);
            const editorState3 = EditorState.createWithContent(contentState3);
            this.state = {
                editorState,
                editorState2,
                editorState3,
            };
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.slug !== prevProps.pages.page.slug) {
            let item = {
                slug :'terms'
            }
            this.props.dispatch(getPageSlug(item))

            let tr1 = htmlToDraft (this.props.pages.page.descriptionKa)
            let tr1a = ContentState.createFromBlockArray(tr1)
            let tr1b =  EditorState.createWithContent(tr1a)

            let tr2 = htmlToDraft (this.props.pages.page.descriptionEn)
            let tr2a = ContentState.createFromBlockArray(tr2)
            let tr2b =  EditorState.createWithContent(tr2a)

            let tr3 = htmlToDraft (this.props.pages.page.descriptionRu)
            let tr3a = ContentState.createFromBlockArray(tr3)
            let tr3b =  EditorState.createWithContent(tr3a)


            this.setState({
                _id : this.props.pages.page._id,
                slug : this.props.pages.page.slug,
                titleKa : this.props.pages.page.titleKa,
                titleEn : this.props.pages.page.titleEn,
                titleRu : this.props.pages.page.titleRu,
                editorState : tr1b,
                editorState2 : tr2b,
                editorState3 : tr3b,
            })
        }
    }


    onEditorStateChange  = (editorState) => {
        this.setState({
            editorState,
        });
    };
    onEditorStateChange2 = (editorState2) => {
        this.setState({
            editorState2,
        });
    };

    onEditorStateChange3 = (editorState3) => {
        this.setState({
            editorState3,
        });
    };

    handleAddTerms = () => {
        let item = {
            slug : 'terms',
            titleKa : this.state.titleKa,
            titleEn :  this.state.titleEn,
            titleRu :  this.state.titleRu,
            descriptionKa : draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
            descriptionEn : draftToHtml(convertToRaw(this.state.editorState2.getCurrentContent())),
            descriptionRu : draftToHtml(convertToRaw(this.state.editorState3.getCurrentContent()))
        }
        this.props.dispatch(createPage(item))
    }


    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }


    render() {
        const { editorState, editorState2, editorState3 } = this.state;
        return (

            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Utility" breadcrumbItem="Terms" />
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col md={4}>
                                        <div className="mb-3">
                                             <Label className="form-label" htmlFor="row-email-input">Title Ka</Label>
                                            <Input
                                                value={this.state.titleKa}
                                                onChange={this.handleChange("titleKa")}
                                                type="text" className="form-control"   />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="row-email-input">Title En</Label>
                                            <Input
                                                value={this.state.titleEn}
                                                onChange={this.handleChange("titleEn")}
                                                type="text" className="form-control"   />
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="row-email-input">Title Ru</Label>
                                            <Input
                                                value={this.state.titleRu}
                                                onChange={this.handleChange("titleRu")}
                                                type="text" className="form-control"   />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>


                        <Card>
                            <CardBody>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange}
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Editor
                                    editorState={editorState2}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange2}
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Editor
                                    editorState={editorState3}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onEditorStateChange3}
                                />
                            </CardBody>
                        </Card>
                        <Row style={{paddingBottom: '20px'}}>
                            <Col md={4}>
                                <Button
                                    onClick={this.handleAddTerms.bind(this)}
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary w-xs waves-effect waves-light"
                                >
                                    <span>  დამატება</span>
                                </Button>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>

        );
    }
}






const mapStateToProps = state => {
    const {
        pages
    } = state
    return {
        pages
    }
};
export default withRouter(connect(mapStateToProps)(Terms));

