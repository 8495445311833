import {GET_OFFICE_REQUEST, GET_OFFICES_REQUEST, UPDATE_OFFICES_REQUEST} from "./actionTypes";


export const getOffices = () => ({
    type: GET_OFFICES_REQUEST
})
export const getOffice = (data) => ({
    type: GET_OFFICE_REQUEST,
    payload :data
})

export const updateOffice = (data) => ({
    type: UPDATE_OFFICES_REQUEST,
    payload :data
})
