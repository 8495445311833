import {
    PRELOADER_ON,
    PRELOADER_OFF,
    PRELOADER_CHECKED_ON,
    PRELOADER_CHECKED_OFF, STEP_ONE_PRELOADER_OFF, STEP_ONE_PRELOADER_ON, PRELOADER_INVOICE_ON, PRELOADER_INVOICE_OFF,
} from "./actionTypes"

const INIT_STATE = {
    preloaderAll : false,
    preloaderChecked : false,
    stepOneResult : false,
    invoice : false,
}

const Invoices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRELOADER_ON:
            return {
                ...state,
                preloaderAll : true
            }
        case PRELOADER_OFF:
            return {
                ...state,
                preloaderAll : false
            }
        case PRELOADER_CHECKED_ON:
            return {
                ...state,
                preloaderChecked : true
            }
        case PRELOADER_CHECKED_OFF:
            return {
                ...state,
                preloaderChecked : false
            }

        case STEP_ONE_PRELOADER_ON:
            return {
                ...state,
                stepOneResult : true
            }

        case STEP_ONE_PRELOADER_OFF:
            return {
                ...state,
                stepOneResult : false
            }
        case PRELOADER_INVOICE_ON:
            return {
                ...state,
                invoice : true
            }

        case PRELOADER_INVOICE_OFF:
            return {
                ...state,
                invoice : false
            }

        default:
            return state
    }
}

export default Invoices
