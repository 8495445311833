import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_REQUEST_SUCCESS,
    GET_USER_TRANSACTIONS_REQUEST,
    GET_USER_TRANSACTIONS_REQUEST_SUCCESS
} from "./actionTypes";
import {notification} from 'antd';

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getTransactionsSaga({payload}) {
    try {
        const callBack = yield call(getTransactionsSagaAsync, payload)
        if (callBack.success){
            yield put({type: GET_TRANSACTIONS_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getTransactionsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/merchant/transactions/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getUserTransactionsSaga({payload}) {
    try {
        const callBack = yield call(getUserTransactionsSagaAsync, payload)
        if (callBack.success){
            yield put({type: GET_USER_TRANSACTIONS_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getUserTransactionsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/merchant/userTransactions/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}





function* newsSaga() {
    yield takeEvery(GET_TRANSACTIONS_REQUEST, getTransactionsSaga)
    yield takeEvery(GET_USER_TRANSACTIONS_REQUEST, getUserTransactionsSaga)
}

export default newsSaga
