/* INVOICES */
export const INVOICES_NULL = "INVOICES_NULL"
export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST"
export const GET_INVOICES_REQUEST_SUCCESS = "GET_INVOICES_REQUEST_SUCCESS"
export const GET_INVOICES_ALL_REQUEST = "GET_INVOICES_ALL_REQUEST"
export const GET_INVOICES_ALL_REQUEST_SUCCESS = "GET_INVOICES_ALL_REQUEST_SUCCESS"

export const GET_ACCOUNTENT_REQUEST = "GET_ACCOUNTENT_REQUEST"
export const GET_ACCOUNTENT_REQUEST_SUCCESS = "GET_ACCOUNTENT_REQUEST_SUCCESS"
export const GET_ACCOUNTENT_NULL = "GET_ACCOUNTENT_NULL"

