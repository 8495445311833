import {
    GET_USERS_REQUEST,
    GET_USER_REQUEST,
    UPDATE_USER_REQUEST,
    DELETE_USER_REQUEST,
    SEARCH_USER_REQUEST, UPDATE_USER_BALANCE_REQUEST, UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST
} from "./actionTypes"

export const getUsers = (data) => ({
    type: GET_USERS_REQUEST,
    payload :data
})

export const getUser = (data) => ({
    type: GET_USER_REQUEST,
    payload :data
})

export const updateUser = (data) => ({
    type: UPDATE_USER_REQUEST,
    payload :data
})
export const updateUserBalance = (data) => ({
    type: UPDATE_USER_BALANCE_REQUEST,
    payload :data
})
export const updateUserIndividualTariff = (data) => ({
    type: UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST,
    payload :data
})

export const deleteUser = (data) => ({
    type: DELETE_USER_REQUEST,
    payload :data
})

export const searchUser = (data) => ({
    type: SEARCH_USER_REQUEST,
    payload :data
})
