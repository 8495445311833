import {
    GET_LOGISTIC_PRODUCT_REQUEST,
    GET_ALL_LOGISTIC_PRODUCTS_REQUEST,
    GET_SENT_FLIGHT_REQUEST,
    GET_SENT_FLIGHT_ID_REQUEST,
    GET_SENT_FLIGHT_ID_OBTAINS_REQUEST, UPDATE_PRODUCT_REQUEST,
    DELETE_PRODUCT_REQUEST,
    GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_REQUEST, SEARCH_PRODUCT_REQUEST,
    DELETE_CHECKED_PRODUCTS_REQUEST,
    NULL_OBJECT_REQUEST,
    GET_GIVEAWAY_REQUEST,
    NULL_MODAL_PRODUCT_REQUEST, GET_DEPT_SUM_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SORTING_REQUEST, GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_NEW_REQUEST, GET_FLIGHTS_PRODUCTS
} from "./actionTypes"




export const nullProductObject = () => ({
    type: NULL_OBJECT_REQUEST
})
export const nullModalProduct = () => ({
    type: NULL_MODAL_PRODUCT_REQUEST
})
export const getLogisticProduct = (data) => ({
    type: GET_LOGISTIC_PRODUCT_REQUEST,
    payload :data
})

export const geDebtSum = (data) => ({
    type: GET_DEPT_SUM_PRODUCT_REQUEST,
    payload :data
})

export const getLogisticProductByTrackingId = (data) => ({
    type: GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_REQUEST,
    payload :data
})

export const getLogisticProductByTrackingIdNew = (data) => ({
    type: GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_NEW_REQUEST,
    payload :data
})


export const getAllLogisticProducts = (data) => ({
    type: GET_ALL_LOGISTIC_PRODUCTS_REQUEST,
    payload :data
})

export const getSentFlight = (data) => ({
    type: GET_SENT_FLIGHT_REQUEST,
    payload :data
})

export const getSentFlightIds = (data) => ({
    type: GET_SENT_FLIGHT_ID_REQUEST,
    payload :data
})
export const getFlightsProducts = (data) => ({
    type: GET_FLIGHTS_PRODUCTS,
    payload :data
})

export const getSentFlightOptains = (data) => ({
    type: GET_SENT_FLIGHT_ID_OBTAINS_REQUEST,
    payload :data
})

export const updateProductSorting = (data) => ({
    type: UPDATE_PRODUCT_SORTING_REQUEST,
    payload :data
})

export const updateProduct = (data) => ({
    type: UPDATE_PRODUCT_REQUEST,
    payload :data
})

export const updateSortingProduct = (data) => ({
    type: UPDATE_PRODUCT_SORTING_REQUEST,
    payload :data
})

export const deleteProduct = (data) => ({
    type: DELETE_PRODUCT_REQUEST,
    payload :data
})

export const deleteCheckedProducts = (data) => ({
    type: DELETE_CHECKED_PRODUCTS_REQUEST,
    payload :data
})


export const searchProduct = (data) => ({
    type: SEARCH_PRODUCT_REQUEST,
    payload :data
})

export const getGiveaway = (data) => ({
    type: GET_GIVEAWAY_REQUEST,
    payload :data
})
