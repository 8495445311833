import {GET_TRANSACTIONS_REQUEST, GET_USER_TRANSACTIONS_REQUEST} from './actionTypes'

export const getTransations = (data) =>({
    type : GET_TRANSACTIONS_REQUEST,
    payload : data
})

export const getUserTransations = (data) =>({
    type : GET_USER_TRANSACTIONS_REQUEST,
    payload : data
})
