import React, {useEffect, useState,useRef } from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Switch from "react-switch"
import {notification} from 'antd';


import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    changeStatusAll,
    changeStatusStep4Cheked, createInvoice,
    getSentFlight,
    getSentFlightIds, getSentFlightOptains
} from "../../store/actions";

import Columns from "./Columns"
import ObjectColumns from "./ObjectColumns";
import ObjectColumnsObtained from "./ObjectColumnsObtained";


const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const Sent = props => {
    const dispatch = useDispatch();
    const [rate, setRate] = useState(1.14)
    const [flightId, setFlightId] = useState(0)
    const [sq1, setsq1] = useState(false)
    const [country, setCountry] = useState(1)
    const [uniqArray, setUniqArray] = useState([])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)

    const [selectProducts, setSelectProducts] = useState([])

    const bstable = useRef([])

    useEffect(() => {
        let item = {
            status : 'obtained'
        }
        dispatch(getSentFlight(item))
    }, [])

    useEffect(() => {
        setRate(props.information.info.rateUSD)
    }, [props.information.info.rateUSD])

    useEffect(() => {
        if (props.products.sentFlight.length > 0) {
            setFlightId(props.products.sentFlight[0]._id)
            let item = {
                id: props.products.sentFlight[0]._id
            }
            dispatch(getSentFlightOptains(item))
        }
    }, [props.products.sentFlight])


    const handleChangeFlight = (i) => {
        let flightId = i.target.value
        setFlightId(flightId)

        let item = {
            id: i.target.value
        }

        dispatch(getSentFlightOptains(item))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="გატანილი"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={6}>
                                            <label className="col-md-2 col-form-label">რეისი</label>
                                            <select
                                                value={flightId}
                                                onChange={handleChangeFlight}
                                                className="form-control">
                                                {
                                                    props.products.sentFlight.map((item, key) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={item.flightId}>რეისი: #{item.flightId} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <br/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col lg={12}>
                            <ObjectColumnsObtained
                                bstable={bstable}
                                logisticProducts={props.products.products}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic,
        information
    } = state
    return {
        products,
        logistic,
        information
    }
};
export default withRouter(connect(mapStateToProps)(Sent));
