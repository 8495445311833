// localStorage.js
export const getLocalState = key => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
export const getJWT = () => {
    try {
        return localStorage.getItem("token")
    } catch (err) {
        return undefined;
    }
};

export const setLocalState = (key, value) => {
    try {
        const serializedState = JSON.stringify(value);
        localStorage.setItem(key, serializedState);
    } catch {
        // ignore write errors
    }
};
