import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row, Card, Col, Modal, FormGroup, Label, CardBody, Button,
} from "reactstrap"
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {getOffice, updateOffice, updateTariff} from "../../store/actions";
import Nulled from "../../components/Null/Nulled";

import {tariffsType, countries} from '../../helpers/status'

const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [product, setProduct] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            setId(id)
            dispatch(getOffice(item))
        }
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        setProduct(props.offices.office)
    }, [props.offices.office._id])

    const Columns = [
        {
            dataField: "countryId",
            text: "ქვეყანა",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {
                        countries(cellContent)
                    }

                </>
            ),
        },

        {
            dataField: "provinceEn",
            text: "პროვინცია",
            sort: true,

        },

        {
            dataField: "cityEn",
            text: "ქალაქი",
            sort: true,
        },

        {
            dataField: "addressEn",
            text: "მისამართი ",
            sort: true,
        },

        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                  <span
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                          tog_large(row._id, key)
                      }}
                      className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>
                </>
            ),
        },

    ]

    const handleUpdateInformation = () => {
        let item = {
            key,
            id,
            product
        }
        dispatch(updateOffice(item))
        setmodal_xlarge(false)
    }

    return (
        <>
            {
                !isEmpty(props.products)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                _id={'_id'}
                                keyField="_id"
                                data={props.products}
                                columns={Columns}
                            />

                        </Card>

                    </>
                    : <Nulled/>
            }

            <Modal size="xl" isOpen={modal_xlarge}
                   toggle={() => {
                       tog_large()
                   }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >

                        {
                            countries(product.countryId)
                        }

                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(product._id)
                            ?
                            <>
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">Province En</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.provinceEn}
                                                onChange={handleChange}
                                                name="provinceEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">City En</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.cityEn}
                                                onChange={handleChange}
                                                name="cityEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">Address En</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.addressEn}
                                                onChange={handleChange}
                                                name="addressEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        product.countryId === '3'
                                        ?
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Address 2 En</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.addressEn2}
                                                        onChange={handleChange}
                                                        name="addressEn2"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            :null
                                    }
                                    {
                                        product.countryId === '3'
                                            ?
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Neigborhood</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.neigborhood}
                                                        onChange={handleChange}
                                                        name="neigborhood"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            :null
                                    }

                                </Row>
                                {
                                    product.countryId === '1'
                                        ? <Row>
                                            <Col md="12">
                                                <hr/>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Province China</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.provinceLoc}
                                                        onChange={handleChange}
                                                        name="provinceLoc"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">City China</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.cityLoc}
                                                        onChange={handleChange}
                                                        name="cityLoc"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">Address China</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.addressLoc}
                                                        onChange={handleChange}
                                                        name="addressLoc"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        : null
                                }

                                <Row>
                                    <Col md="12">
                                        <hr/>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">Postal Code</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.postalCode}
                                                onChange={handleChange}
                                                name="postalCode"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">Mobile</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.mobile}
                                                onChange={handleChange}
                                                name="mobile"
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </div>
            </Modal>

        </>
    )
}

const mapStateToProps = state => {
    const {
        offices,
    } = state
    return {
        offices,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));

