import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {Row, Card, Col, Modal, FormGroup, Label, Button,} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {countries} from '../../helpers/status'
import {
    deleteAdmin,
} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Nulled from "../../components/Null/Nulled";

import {role} from '../../helpers/status'

const ObjectColumns = props => {
    const dispatch = useDispatch();

    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})


    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteAdmin(item))
        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 100,
        totalSize: props.usersCount - 100, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }


    const Columns = [
        {
            dataField: "email",
            text: "ლოგინი",
            sort: true,
        },

        {
            dataField: "_id",
            text: "როლი",
            formatter: (cellContent, row, key) => (
                <>
                    {role(row.role)}
                </>
            ),
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    {/*          <span>
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    tog_large(row._id, key)
                                }}
                                className="px-3 text-primary">
                                <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                            </span>

                       </span>*/}
                    {
                        row.role !== 1
                            ?
                            <span>
                              <FeatherIcon
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                      handleOpenAlertModal(row._id, key)
                                  }}
                                  icon="trash" className="icon-xs icon me-2 text-danger"/>
                           </span>
                            : null
                    }

                </>
            ),
        },

    ]
    return (
        <>
            {
                !isEmpty(props.usersArray)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                keyField="_id"
                                data={props.usersArray}
                                columns={Columns}
                            />

                        </Card>
                    </>
                    : <Nulled/>
            }
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}

const mapStateToProps = state => {
    const {
        admin,
    } = state
    return {
        admin,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));
