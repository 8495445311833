import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, FormGroup, Label, Row,} from "reactstrap";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getFlights} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import ClipLoader from "react-spinners/ClipLoader";

const Index = props => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState(1)
    const [skip, setSkip] = useState(0)
    const [type, setType] = useState('all')
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        let item = {
            country: country,
            type: type,
            skip: 0,
            limit: limit,
        }
        dispatch(getFlights(item))
    }, [])


    useEffect(() => {
        let item = {
            country: country,
            type: type,
            skip: 0,
            limit: limit,
        }
        dispatch(getFlights(item))
    }, [type])

    const handlePagination = (page) => {
       let item = {
            country: country,
            type: type,
            skip: page,
            limit: limit,
        }
       dispatch(getFlights(item))
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="რეისები"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="button-items">
                                                <Button color="primary"
                                                        onClick={() => setType(1)}
                                                        className="btn-soft-primary waves-effect waves-light">China </Button>{" "}
                                                <Button color="success"
                                                        onClick={() => setType(3)}
                                                        className="btn-soft-success waves-effect waves-light">Turkey
                                                </Button>{" "}
                                                <Button color="info"
                                                        onClick={() => setType(2)}
                                                        className="btn-soft-info waves-effect waves-light">Usa </Button>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                pagination={handlePagination}
                                usersArray={props.flight.flights}
                                usersCount={props.flight.flightsCount}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        flight,
    } = state
    return {
        flight
    }
};
export default withRouter(connect(mapStateToProps)(Index));
