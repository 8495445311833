import {
  GET_ACCOUNTENT_NULL,
  GET_ACCOUNTENT_REQUEST,
  GET_INVOICES_ALL_REQUEST,
  GET_INVOICES_REQUEST, INVOICES_NULL
} from "./actionTypes"

export const NullInvoice = () => ({
  type: INVOICES_NULL
})
export const getInvoices = (data) => ({
  type: GET_INVOICES_REQUEST,
  payload :data
})

export const getInvoicesAll = (data) => ({
  type: GET_INVOICES_ALL_REQUEST,
  payload :data
})


export const getAccountant = (data) => ({
  type: GET_ACCOUNTENT_REQUEST,
  payload :data
})

export const accountantNull = (data) => ({
  type: GET_ACCOUNTENT_NULL,
  payload :data
})


