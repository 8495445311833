import {
    GET_RATES_REQUEST,
    CREATE_RATES_REQUEST
} from "./actionTypes"

export const getRates = () => ({
    type:GET_RATES_REQUEST
})


export const createRates = () => ({
    type:CREATE_RATES_REQUEST
})

