import {
    GET_USER_REQUEST_SUCCESS,
    GET_USERS_REQUEST_SUCCESS,
    DELETE_USER_REQUEST_SUCCESS, UPDATE_USER_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    users: [],
    usersAll: 0,
    usersCount: 0,
    usersFiz: 0,
    usersIur: 0,
    usersIind: 0,
    user: {},
    userIndividualTariff: []
}

const Invoices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USERS_REQUEST_SUCCESS:
            return {
                ...state,
                users: action.callBack.users,
                usersAll: action.callBack.usersAll,
                usersCount: action.callBack.usersCount,
                usersFiz: action.callBack.usersFiz,
                usersIur: action.callBack.usersIur,
                usersIind: action.callBack.usersIind
            }
        case GET_USER_REQUEST_SUCCESS:
            return {
                ...state,
                user: action.callBack.user,
                userIndividualTariff: action.callBack.infividualTariffs,
            }
        case DELETE_USER_REQUEST_SUCCESS:
            return {
                ...state,
                users: state.users.filter(todo => todo._id !== action.payload.id)
            }
        case UPDATE_USER_REQUEST_SUCCESS:
            state.users[action.payload.key] = action.payload.user
            return {
                ...state,
            }

        default:
            return state
    }
}

export default Invoices
