import {
    GET_NEWS_REQUEST_SUCCESS,
    GET_NEW_REQUEST_SUCCESS, DELETE_NEWS_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    page :{
        _id :''
    }
}

const Pages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEWS_REQUEST_SUCCESS:
            return {
                ...state,
                array :  action.callBack.news,
            }
        case GET_NEW_REQUEST_SUCCESS:
            return {
                ...state,
                page :  action.callBack.page,
            }

        case DELETE_NEWS_REQUEST_SUCCESS:
            return {
                ...state,
                array: state.array.filter(todo => todo._id !== action.payload.id)
            }

        default:
            return state
    }
}

export default Pages
