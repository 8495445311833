import {
    CHECK_ADMIN_REQUEST_SUCCESS, IS_AUTHENTICAL_STATUS,
} from "./actionTypes"

const INIT_STATE = {
    isAuthenticated: null,
    admin : {}
}

const auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHECK_ADMIN_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated:true,
                admin: action.payload.candidate,
            }

        case IS_AUTHENTICAL_STATUS:
            return {
                ...state,
                isAuthenticated: action.item.status
            }

        default:
            return state
    }
}

export default auth
