import React, {useEffect} from "react";
import {Card, CardBody, Table, CardTitle, Label, Input, Row, Col, Button} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {getDashboardInformation} from "../../store/information/actions";
import {isEmpty} from "lodash";
import Moment from 'react-moment';
const LatestTransaction = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashboardInformation())
    }, [])

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle className="h4 mb-4">Latest Transaction</CardTitle>
                        <div className="table-responsive">
                            <Table className="table-centered table-nowrap mb-0">
                                <thead className="table-light">
                                <tr>
                                    <th>Order ID</th>
                                    <th>AXID</th>
                                    <th>დანიშნულება</th>
                                    <th>Date</th>
                                    <th>Total</th>
                                    <th>Payment Method</th>
                                    <th>Payment Status</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    !isEmpty(props.information.transactionArray)
                                        ?
                                        <>
                                            {
                                                props.information.transactionArray.map((item, key) =>{
                                                    return (
                                                        <tr key={key}>

                                                            <td><span  className="text-body fw-bold">{item._id}</span></td>
                                                            <td>{item.userAXID}</td>
                                                            <td>{item.destination}</td>
                                                            <td>
                                                                <Moment format="YYYY-MM-DD"
                                                                        date={item.createdAt}/>
                                                            </td>
                                                            <td>
                                                                <i>Amount: {item.amount} ₾</i><br/>
                                                                <i>Old Status: {item.oldStatus}  ₾</i><br/>
                                                                <i>new Status: {item.newStatus}  ₾</i>

                                                            </td>
                                                            <td>
                                                                <span className="badge rounded-pill bg-soft-success font-size-12">{item.destination}</span>
                                                            </td>
                                                            <td>
                                                                <span className="badge rounded-pill bg-soft-success font-size-12">{item.status}</span>
                                                            </td>
                                                            <td>
{/*
                                                                <Button type="button" color="primary"
                                                                        className="btn-sm btn-rounded waves-effect waves-light">
                                                                    View Details
                                                                </Button>*/}
                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }
                                        </>
                                        : null

                                }



                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    const {
        information
    } = state
    return {
        information
    }
};
export default withRouter(connect(mapStateToProps)(LatestTransaction));

