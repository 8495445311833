import {call, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_PRINT_REQUEST, CREATE_PRINT_REQUEST_SUCCESS,
} from "./actionTypes";
import {notification} from 'antd';

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* createPrintSaga({payload}) {
    try {
        const callBack = yield call(createPrintSagaAsync, payload)
        if (callBack.success) {
            modalSuccess('success')
            yield put({type: CREATE_PRINT_REQUEST_SUCCESS, payload})
        }else{
            modalError('error')
        }
    } catch (e) {
        console.error(e);
    }
}

const createPrintSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/print/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* popapSaga() {
    yield takeEvery(CREATE_PRINT_REQUEST, createPrintSaga)
}

export default popapSaga
