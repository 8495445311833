import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody,Col, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getUsers, searchProduct, searchUser} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";


const Index = props => {
    const dispatch = useDispatch();
    const [skip, setSkip] = useState(0)
    const [type, setType] = useState('all')
    const [limit, setLimit] = useState(10)


    useEffect(() => {
        let item = {
            id: props.match.params.id,
        }
        dispatch(searchUser(item))
    }, [props.match.params.id])


    const handlePagination = (page) =>{

    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="მომხარებლები"/>

                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                page='search'
                                pagination={handlePagination}
                                usersArray={props.users.users}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        users,
    } = state
    return {
        users
    }
};
export default withRouter(connect(mapStateToProps)(Index));
