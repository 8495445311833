import {GET_NEWS_REQUEST, GET_NEW_REQUEST, DELETE_NEWS_REQUEST} from './actionTypes'


export const getNews = (data) =>({
    type : GET_NEWS_REQUEST,
    payload : data
})

export const getNew = (data) =>({
    type : GET_NEW_REQUEST,
    payload : data
})


export const deleteNews = (data) =>({
    type : DELETE_NEWS_REQUEST,
    payload : data
})
