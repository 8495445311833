import {CREATE_POPAP_NOTIFICATION_REQUEST ,
    UPDATE_POPAP_STATUS_REQUEST, GET_POPAP_STATUS_REQUEST} from './actionTypes'

export const createPopapNotification = (data) =>({
    type : CREATE_POPAP_NOTIFICATION_REQUEST,
    payload : data
})

export const updatePopapStatus = (data) =>({
    type : UPDATE_POPAP_STATUS_REQUEST,
    payload : data
})

export const getPopap = (data) =>({
    type : GET_POPAP_STATUS_REQUEST,
    payload : data
})
