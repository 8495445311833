import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {Row, Card, Col, Modal, FormGroup, Label, Button,} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {countries} from '../../helpers/status'
import {
    deleteProduct,
    deleteUser,
    getUser,
    updateProduct,
    updateUser,
    updateUserBalance,
    updateUserIndividualTariff
} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Nulled from "../../components/Null/Nulled";
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";


const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [password, setPassword] = useState('')
    const [passwordBalance, setPasswordBalance] = useState('')

    const [countryErr, setCountryErr] = useState(false)
    const [amountErr, setAmountErr] = useState(false)

    const [passwordErr, setPasswordErr] = useState(false)
    const [passwordBalanceErr, setPasswordBalanceErr] = useState(false)
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)


    const [user, setUser] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    const [amount, setAmount] = useState('')
    const [country, setCountry] = useState('')
    //
    useEffect(() => {
        setAmount('')
        setCountry('')
        if (props.users.user._id) {
            let n = props.users.user.balance
            props.users.user.balance = n.toFixed(2)
            setUser(props.users.user)
        }

        setPassword('')
    }, [props.users.user._id])


    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getUser(item))
        }
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }


    const handleChangeCheckBox = e => {
        const {name, value} = e.target;
        setUser(prevState => ({
            ...prevState,
            information: {...prevState.information, ['indEntrepreneur']: !user.information.indEntrepreneur}
        }));
    };

    const handleChange = e => {
        const {name, value} = e.target;

        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setUser(prevState => ({
            ...prevState,
            information: {...prevState.information, [name]: value}
        }));
    };

    const handleAddressChange = (newAddress) => {
        setUser((prevUser) => ({
            ...prevUser,
            takedPlaced: {
                ...prevUser.takedPlaced,
                address: newAddress
            }
        }));
    };


    const handleUpdateInformation = () => {

        if (password === process.env.REACT_APP_UPDATE_PASSWORD) {
            let item = {
                key,
                user
            }
            dispatch(updateUser(item))
            setmodal_xlarge(false)
            setPasswordErr(false)
        } else {
            setPasswordErr(true)
        }


    }

    const handleUpdateBalance = () => {

        if (passwordBalance === process.env.REACT_APP_BALANCE_UPDATE_PASSWORD) {
            let item = {
                key,
                user
            }
            dispatch(updateUserBalance(item))
            setmodal_xlarge(false)
            setPasswordBalanceErr(false)
        } else {
            setPasswordBalanceErr(true)
        }
    }
    const handleUpdateIndividualTariff = () => {
        if (password === process.env.REACT_APP_UPDATE_PASSWORD) {
            setPasswordErr(false)

            if (amount && country) {
                let item = {
                    key,
                    user,
                    amount,
                    country
                }
                dispatch(updateUserIndividualTariff(item))
                setmodal_xlarge(false)
                setPasswordErr(false)
            } else {
                if (!amount) setAmountErr(true)
                if (!country) setCountryErr(true)
            }

        } else {
            setPasswordErr(true)
        }
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteUser(item))
        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 100,
        totalSize: props.usersCount - 100, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }


    const Columns = [
        {
            dataField: "AXID",
            text: "AXID",
            sort: true,
        },
        {
            dataField: "email",
            text: "ელ-ფოსტა",
            sort: true,
        },
        {
            dataField: "information.nameKa",
            text: "სახელი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.type) === 1
                            ? <>
                                {
                                    row.information.nameKa
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.type) === 2
                            ?
                            <>
                                {
                                    row.information.indEntrepreneur
                                        ? row.information.nameKa
                                        : row.information.LTDName
                                }
                            </>
                            : null
                    }

                </>
            ),
        },
        {

            text: "გვარი",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.type) === 1
                            ? <>
                                {
                                    row.information.lastNameKa
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.type) === 2
                            ?
                            <>
                                {
                                    row.information.indEntrepreneur
                                        ? row.information.lastNameKa
                                        : null
                                }
                            </>
                            : null
                    }
                </>
            ),
        },

        {

            text: "სახელი En",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.type) === 1
                            ? <>
                                {
                                    row.information.nameEn
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.type) === 2
                            ?
                            <>
                                {
                                    row.information.indEntrepreneur
                                        ? row.information.nameEn
                                        : row.information.LTDNameEn
                                }
                            </>
                            : null
                    }

                </>
            ),
        },
        {

            text: "გვარი En",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.type) === 1
                            ? <>
                                {
                                    row.information.lastNameEn
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.type) === 2
                            ?
                            <>
                                {
                                    row.information.indEntrepreneur
                                        ? row.information.lastNameEn
                                        : null
                                }
                            </>
                            : null
                    }
                </>
            ),
        },
        {

            text: "პირადობის ნომერი / ID",
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {
                        parseInt(row.type) === 1
                            ? <>
                                {
                                    row.information.passportId
                                }
                            </>
                            : null
                    }
                    {
                        parseInt(row.type) === 2
                            ? <>
                                {
                                    row.information.indEntrepreneur
                                        ? row.information.passportId
                                        : row.information.orgId
                                }
                            </>
                            : null
                    }

                </>
            ),
        },
        {
            dataField: "phone",
            text: "ტელეფონი",
            sort: true,
        },

        {

            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <span
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            tog_large(row._id, key)
                        }}
                        className="px-3 text-primary">
                         <Link to={`/merchant/user/${row.AXID}`}>
                            <FeatherIcon icon="dollar-sign" className="icon-xs icon me-2 text-pending"/>
                         </Link>

                            </span>
                    <span
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            tog_large(row._id, key)
                        }}
                        className="px-3 text-primary">
                                <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                            </span>
                    <span>
                          <FeatherIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                  handleOpenAlertModal(row._id, key)
                              }}
                              icon="trash" className="icon-xs icon me-2 text-danger"/>
                       </span>
                </>
            ),
        },
    ]
    return (
        <>
            {
                !isEmpty(props.usersArray)
                    ?
                    <>
                        <PaginationProvider
                            pagination={paginationFactory(paginationOption)}
                        >
                            {
                                ({
                                     paginationProps,
                                     paginationTableProps
                                 }) => (
                                    <Row>
                                        <Card>
                                            <BootstrapTable
                                                keyField="_id"
                                                data={props.usersArray}
                                                columns={Columns}
                                                {...paginationTableProps}
                                            />
                                        </Card>
                                        {
                                            props.page === 'search'
                                                ? null
                                                :
                                                <div className="float-sm-end">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </div>
                                        }

                                    </Row>
                                )
                            }
                        </PaginationProvider>
                    </>
                    : <Nulled/>
            }

            <Modal size="xl" isOpen={modal_xlarge} toggle={() => {
                tog_large()
            }}>
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        მომხმარებლის AXID: {user.AXID}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(user._id)
                            ?
                            <>

                                <Row>
                                    <Col md="12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">დამადასტურებელი პაროლი</Label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                style={passwordErr ? {borderColor: 'red'} : null}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                name="password"
                                            />
                                            {
                                                passwordErr
                                                    ? <i style={{color: 'red'}}>დამადასტურებელი პაროლი არასწორია</i>
                                                    : null
                                            }

                                        </FormGroup>

                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        user.information.indEntrepreneur
                                    }
                                    <Col md="12">
                                        {
                                            parseInt(user.type) === 1
                                                ? <h3 style={{color: '#5b73e8'}}>ფიზიკური პირი</h3>
                                                : null
                                        }
                                        {
                                            parseInt(user.type) === 2
                                                ? <h3 style={{color: '#5b73e8'}}>იურიდიული პირი</h3>
                                                : null
                                        }
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label>მომხმარებლის ტიპი</Label>
                                            <select
                                                value={user.type}
                                                onChange={handleChange}
                                                name="type"
                                                className="form-control form-control-border">
                                                <option
                                                    value={1}>ფიზიკური პირი
                                                </option>
                                                <option
                                                    value={2}>იურიდიული პირი
                                                </option>
                                            </select>
                                        </FormGroup>
                                    </Col>


                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label>ელ ფოსტა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.email}
                                                onChange={handleChange}
                                                name="email"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.phone}
                                                onChange={handleChange}
                                                name="phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.information.nameKa}
                                                onChange={handleChangeInformation}
                                                name="nameKa"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">გვარი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.information.lastNameKa}
                                                onChange={handleChangeInformation}
                                                name="lastNameKa"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">სახელი En</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.information.nameEn}
                                                onChange={handleChangeInformation}
                                                name="nameEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">გვარი En</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.information.lastNameEn}
                                                onChange={handleChangeInformation}
                                                name="lastNameEn"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">address</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.information.address}
                                                onChange={handleChangeInformation}
                                                name="address"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">passportId</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={user.information.passportId}
                                                onChange={handleChangeInformation}
                                                name="passportId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label> უცხო ქვეყნის მოქალაქე</Label>
                                            <input
                                                //  onChange={indEntrepreneur}
                                                onClick={handleChangeCheckBox}
                                                name="nonGeorgianCitizen"
                                                defaultChecked={user.information.nonGeorgianCitizen}
                                                type="checkbox"
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        parseInt(user.type) === 2
                                            ?
                                            <>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label> ინდ. მეწარმე</Label>
                                                        <input
                                                            //  onChange={indEntrepreneur}
                                                            onClick={handleChangeCheckBox}
                                                            name="indEntrepreneur"
                                                            defaultChecked={user.information.indEntrepreneur}
                                                            type="checkbox"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">LTD Name</Label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={user.information.LTDName}
                                                            onChange={handleChangeInformation}
                                                            name="LTDName"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label>LTD Name En</Label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={user.information.LTDNameEn}
                                                            onChange={handleChangeInformation}
                                                            name="LTDNameEn"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">Org Id</Label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={user.information.orgId}
                                                            onChange={handleChangeInformation}
                                                            name="orgId"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <a target="_blank"
                                                           href={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/profile/${user.information.indMecarmeFile}`}>File</a>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                            : null

                                    }

                                </Row>
                                <Row>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">ფილიალი</Label>
                                            <select
                                                value={user.takedPlaced.address}
                                                onChange={(e) => handleAddressChange(e.target.value)}
                                                name="takedPlaced"
                                                className="form-control form-control-border">
                                                <option
                                                    value={''}>აირჩიერთ ერთ ერთი
                                                </option>
                                                <option value={'gldani'}>გლდანი</option>
                                                <option value={'didube'}>დიდუბე</option>
                                                <option value={'isani'}>ისანი</option>
                                                <option value={'kutaisi_1'}>kutaisi_1</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <FormGroup className="mb-3">
                                        <hr/>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Row>
                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                {/*parseFloat(item.rate)*/}
                                                <Label htmlFor="validationCustom02">ბალანსი!</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={user.balance}
                                                    // value={user.balance}
                                                    onChange={handleChange}
                                                    name="balance"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">დამადასტურებელი პაროლი</Label>
                                                <input
                                                    className="form-control"
                                                    autoComplete="off"
                                                    tabIndex="-1"
                                                    type="password"
                                                    style={passwordBalanceErr ? {borderColor: 'red'} : null}
                                                    value={passwordBalance}
                                                    onChange={(e) => setPasswordBalance(e.target.value)}
                                                    name="password"
                                                />
                                                {
                                                    passwordBalanceErr
                                                        ? <i style={{color: 'red'}}>დამადასტურებელი პაროლი არასწორია</i>
                                                        : null
                                                }

                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Label>პაროლი აუცილებელია!</Label>
                                            <Button
                                                onClick={handleUpdateBalance}
                                                color="primary"
                                                className="btn btn-primary waves-effect waves-light"
                                            >
                                                ბალანსის შევსება
                                            </Button>
                                        </Col>
                                    </Row>
                                </Row>


                                {
                                    props.auth.admin.role === 1
                                        ?
                                        <>
                                            <Row>
                                                <FormGroup className="mb-3">
                                                    <hr/>
                                                </FormGroup>
                                            </Row>

                                            <Row>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label>ინდივიდუალური ტარიფი</Label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={amount}
                                                            style={amountErr ? {borderColor: 'red'} : null}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                            name="amount"
                                                        />
                                                        {
                                                            amountErr
                                                                ? <i style={{color: 'red'}}>ეს გრაფა ცარიელი არ უნდა
                                                                    იყოს</i>
                                                                : null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="3">
                                                    <FormGroup className="mb-3">
                                                        <Label>ინდივიდუალური ტარიფი</Label>
                                                        <select
                                                            value={country}
                                                            onChange={(e) => setCountry(e.target.value)}
                                                            name="country"
                                                            style={countryErr ? {borderColor: 'red'} : null}
                                                            className="form-control">
                                                            <option value="">--</option>
                                                            <option value="1">China</option>
                                                            <option value="3">Turkey</option>

                                                        </select>
                                                        {
                                                            countryErr
                                                                ? <i style={{color: 'red'}}>ეს გრაფა ცარიელი არ უნდა
                                                                    იყოს</i>
                                                                : null
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup className="mb-6" style={{textAlign: 'center'}}>
                                                        {
                                                            props.users.userIndividualTariff.length > 0
                                                                ?
                                                                <>
                                                                    ინდივიდუალური ტარიფი:
                                                                    {
                                                                        props.users.userIndividualTariff.map((item, key) => {
                                                                            return (
                                                                                <h3 key={key}>{countries(item.country)} : {item.amount} {item.currency}</h3>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                                : <h3> ამ უზერს არ აქვს ინდივიდუალური ტარიფები </h3>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="3">
                                                    <Button
                                                        onClick={handleUpdateIndividualTariff}
                                                        color="primary"
                                                        className="btn btn-primary waves-effect waves-light"
                                                    >
                                                        ინდივიდუალური ტარიფების დამახსოვრება
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                }


                            </>
                            : null
                    }

                </div>
            </Modal>
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (

                    <SweetAlert
                        input
                        required
                        inputType="password"
                        title="შეიყვანეთ პაროლი"
                        validationMsg="You must enter your password!"
                        onConfirm={(password) => {
                            if (password==='qwerty1234') {
                                handleRemove()
                            }
                        }}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onCancel={() => setconfirm_alert(false)}
                    />

                ) : null}
            </Col>

        </>
    )
}

const mapStateToProps = state => {
    const {
        auth,
        users,
    } = state
    return {
        auth,
        users,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));
