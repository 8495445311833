import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
// import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import invoiceSaga from "./invoices/saga"
import OfficesSaga from "./office/saga"

//
import AuthSaga from "./auth/saga"
import AdminSaga from "./admin/saga"
import InformationSaga from "./information/saga"
import ProductsSaga from "./products/saga"
import LogisticSaga from "./logistic/saga"
import FlightSaga from "./flight/saga"
import UsersSaga from "./user/saga"
import TariffsSaga from "./tariffs/saga"
import PopapSaga from "./popap/saga"
import NotificationSaga from "./notification/saga"
import PageSaga from "./page/saga"
import NewsSaga from "./news/saga"
import CourierSaga from "./courier/saga"
import MerchantSaga from "./merchant/saga"
import RateSaga from "./rate/saga"
import PrintSaga from "./print/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),

    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),

    fork(invoiceSaga),
    fork(AdminSaga),
    fork(AuthSaga),
    fork(InformationSaga),
    fork(ProductsSaga),
    fork(LogisticSaga),
    fork(FlightSaga),
    fork(UsersSaga),
    fork(TariffsSaga),
    fork(OfficesSaga),
    fork(PopapSaga),
    fork(PageSaga),
    fork(NotificationSaga),
    fork(NewsSaga),
    fork(CourierSaga),
    fork(MerchantSaga),
    fork(RateSaga),
    fork(PrintSaga),
  ])
}
