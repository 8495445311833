import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Modal, Row, Table} from "reactstrap";

import {notification} from 'antd';


import Breadcrumbs from "../../components/Common/Breadcrumb";
import Dropzone from "react-dropzone";
import {getLogisticProduct} from "../../store/products/actions";
import {getNew} from "../../store/news/actions";


const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};
const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};

const Detail = props => {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});
    const [selectedFiles, setselectedFiles] = useState([])


    //getNew

    useEffect(() => {
        const {id} = props.match.params;
        let item = {
            id: id,
        }
        dispatch(getNew(item))
        setInputs(props.News.page)
    }, [props.News.page._id])
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
       /* Object.keys(inputs).map(function (key, index) {
            setInputs(prevState => ({
                ...prevState,
                [key]: ''
            }));
        });*/
        const formData = new FormData();
        formData.append('file', selectedFiles[0]);
        formData.append('inputs', JSON.stringify(inputs));

        fetch(`${process.env.REACT_APP_SERVER_URL}/api2/news/update`, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                if (data.result === true) {
                    modalSuccess('success')
                } else {
                    alert('მოხდა შეცდომა')
                }
            })
            .catch(error => {
                console.error(error)
            })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="" breadcrumbItem="სიახლის რედაქტირება"/>
                    <Card>
                        <CardBody>
                            <div className="button-items">

                                <form onSubmit={handleSubmit}>
                                    <Row style={{paddingBottom: '20px'}}>
                                        <Col md={4}>
                                            <Label>Meta Title ქართულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaTitleKa}
                                                name="metaTitleKa" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Meta Title ინგლისურად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaTitleEn}
                                                name="metaTitleEn" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Meta Title რუსულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaTitleRu}
                                                name="metaTitleRu" onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: '20px'}}>
                                        <Col md={4}>
                                            <Label>Meta description ქართულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaDescriptionKa}
                                                name="metaDescriptionKa" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Meta description ინგლისურად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaDescriptionEn}
                                                name="metaDescriptionEn" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Meta description რუსულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaDescriptionRu}
                                                name="metaDescriptionRu" onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: '20px'}}>
                                        <Col md={4}>
                                            <Label>Meta keywords ქართულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaKeywordsKa}
                                                name="metaKeywordsKa" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Meta keywords ინგლისურად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaKeywordsEn}
                                                name="metaKeywordsEn" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Meta keywords რუსულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.metaKeywordsRu}
                                                name="metaKeywordsRu" onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{paddingBottom: '20px'}}>
                                        <Col md={4}>
                                            <Label>სიახლის სათაური ქართულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.newsTitleKa}
                                                name="newsTitleKa" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>სიახლის სათაური ინგლისურად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.newsTitleEn}
                                                name="newsTitleEn" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>სიახლის სათაური რუსულად</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputs.newsTitleRu}
                                                name="newsTitleRu" onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{paddingBottom: '20px'}}>
                                        <Col md={4}>
                                            <Label>პოსტი ქართულად</Label>
                                            <Input
                                                type="textarea"
                                                style={{height: '220px'}}
                                                value={inputs.newsTextKa}
                                                name="newsTextKa" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>პოსტი ინგლისურად</Label>
                                            <Input
                                                type="textarea"
                                                style={{height: '220px'}}
                                                value={inputs.newsTextEn}
                                                name="newsTextEn" onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label>პოსტი რუსულად</Label>
                                            <Input
                                                type="textarea"
                                                style={{height: '220px'}}
                                                value={inputs.newsTextRu}
                                                name="newsTextRu" onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Dropzone
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles)
                                                }}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted uil uil-cloud-upload"/>
                                                            </div>
                                                            <h4>Drop files here or click to upload.</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                {selectedFiles.map((f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{paddingBottom: '20px'}}>
                                        <Col md={4}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn btn-primary w-xs waves-effect waves-light"
                                            >
                                                <span>  დამატება</span>
                                            </Button>
                                        </Col>
                                    </Row>

                                </form>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>


        </React.Fragment>

    )
}

const mapStateToProps = state => {
    const {
        News,
    } = state
    return {
        News
    }
};
export default withRouter(connect(mapStateToProps)(Detail));
