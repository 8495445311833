export const UPDATE_CHANGE_STATUS_STEP_1_SUCCESS = "UPDATE_CHANGE_STATUS_STEP_1_SUCCESS"

export const GET_DEPT_SUM_PRODUCT_REQUEST = "GET_DEPT_SUM_PRODUCT_REQUEST"
export const GET_DEPT_SUM_PRODUCT_REQUEST_SUCCESS = "GET_DEPT_SUM_PRODUCT_REQUEST_SUCCESS"

export const GET_LOGISTIC_PRODUCT_REQUEST = "GET_LOGISTIC_PRODUCT_REQUEST"
export const GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS = "GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS"

export const GET_ALL_LOGISTIC_PRODUCTS_REQUEST = "GET_ALL_LOGISTIC_PRODUCTS_REQUEST"
export const GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS = "GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS"
export const GET_SENT_FLIGHT_REQUEST = "GET_SENT_FLIGHT_REQUEST"
export const GET_SENT_FLIGHT_REQUEST_SUCCESS = "GET_SENT_FLIGHT_REQUEST_SUCCESS"
export const GET_SENT_FLIGHT_ID_REQUEST = "GET_SENT_FLIGHT_ID_REQUEST"
export const GET_SENT_FLIGHT_ID_REQUEST_SUCCESS = "GET_SENT_FLIGHT_ID_REQUEST_SUCCESS"
export const GET_SENT_FLIGHT_ID_OBTAINS_REQUEST = "GET_SENT_FLIGHT_ID_OBTAINS_REQUEST"
export const GET_SENT_FLIGHT_ID_OBTAINS_REQUEST_SUCCESS = "GET_SENT_FLIGHT_ID_OBTAINS_REQUEST_SUCCESS"

export const NULL_MODAL_PRODUCT_REQUEST = "NULL_MODAL_PRODUCT_REQUEST"
export const GET_GIVEAWAY_REQUEST = "GET_GIVEAWAY_REQUEST"
export const GET_GIVEAWAY_REQUEST_SUCCESS = "GET_GIVEAWAY_REQUEST_SUCCESS"

export const UPDATE_PRODUCT_SORTING_REQUEST = "UPDATE_PRODUCT_SORTING_REQUEST"
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST"
export const UPDATE_PRODUCT_REQUEST_SUCCESS = "UPDATE_PRODUCT_REQUEST_SUCCESS"
export const UPDATE_SORTING_PRODUCT_REQUEST = "UPDATE_SORTING_PRODUCT_REQUEST"
export const UPDATE_SORTING_PRODUCT_REQUEST_SUCCESS = "UPDATE_SORTING_PRODUCT_REQUEST_SUCCESS"

export const DELETE_CHECKED_PRODUCTS_REQUEST = "DELETE_CHECKED_PRODUCTS_REQUEST"
export const DELETE_CHECKED_PRODUCTS_REQUEST_SUCCESS = "DELETE_CHECKED_PRODUCTS_REQUEST_SUCCESS"

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST"

export const DELETE_PRODUCT_REQUEST_SUCCESS = "DELETE_PRODUCT_REQUEST_SUCCESS"
export const GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_REQUEST = "GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_REQUEST"
export const GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_NEW_REQUEST = "GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_NEW_REQUEST"

export const SEARCH_PRODUCT_REQUEST = "SEARCH_PRODUCT_REQUEST"
export const SEARCH_PRODUCT_REQUEST_SUCCESS = "SEARCH_PRODUCT_REQUEST_SUCCESS"

export const NULL_OBJECT_REQUEST = "NULL_OBJECT_REQUEST"

export const GET_FLIGHTS_PRODUCTS = "GET_FLIGHTS_PRODUCTS"
export const GET_FLIGHTS_PRODUCTS_SUCCESS = "GET_FLIGHTS_PRODUCTS_SUCCESS"
