import React, {useEffect, useState, useRef} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {notification} from 'antd';


import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    changeStatusAll,
    changeStatusStep4Cheked, createInvoice,
    getSentFlight,
    getSentFlightIds, nullProductObject
} from "../../store/actions";

import Columns from "./Columns"
import ObjectColumns from "./ObjectColumns";


const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const Sent = props => {
    const dispatch = useDispatch();
    const [rate, setRate] = useState(1.14)
    const [flightId, setFlightId] = useState(0)
    const [sq1, setsq1] = useState(true)
    const [country, setCountry] = useState(1)
    const [uniqArray, setUniqArray] = useState([])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)

    const [location, setLocation] = useState('all')

    const [selectProducts, setSelectProducts] = useState([])

    const bstable = useRef(null)

    useEffect(() => {
        dispatch(nullProductObject())
        let item = {
            status: 'sent'
        }
        dispatch(getSentFlight(item))
    }, [])

    useEffect(() => {
        setRate(props.information.info.rateUSD)
    }, [props.information.info.rateUSD])

    useEffect(() => {
        if (props.products.sentFlight.length > 0) {
            setFlightId(props.products.sentFlight[0]._id)
            let item = {
                id: props.products.sentFlight[0]._id,
                status: 'sent',
                location: 'all'
            }
            dispatch(getSentFlightIds(item))
        }

    }, [props.products.sentFlight])


    function onRowSelect(row, isSelected) {
        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }

    const onSelectAll = (isSelected) => {
        if (isSelected) {
            props.products.products.map((item) => {
                setSelectProducts(prevArray =>
                    [...prevArray, item._id]
                )
            })
        } else {
            setSelectProducts([])
        }
    }

    const handleChangeStatusChecked = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setUniqArray(uniqArray)

        if (uniArray.length === 0) {
            modalWarning('warning');
        } else {
            let item = {
                sendMessage: sq1,
                ids: uniArray,
                rate: rate,
                flightId: flightId,
                country: country,
                status: 'sent',
                creater: 'all',
                skip: skip,
                limit: limit,
            }
            dispatch(changeStatusStep4Cheked(item))
        }
    }
    const handleCreteInvoice = () => {
        let item = {
            flightId: flightId,
            status: 'sent',
            location
        }
        dispatch(createInvoice(item))
    }

    const handleChangeStatusAll = () => {
        let item = {
            sendMessage: sq1,
            rate: rate,
            country: country,
            status: 'sent',
            creater: 'all',
            skip: skip,
            limit: limit,
            flightId: flightId,
        }
        dispatch(changeStatusAll(item))
    }

    const handleChangeFlight = (i) => {
        // console.log("!!!!",i.target.getAttribute('data-country')  )
        setCountry(i.target.selectedOptions[0].getAttribute('data-country'))
        if (props.products.sentFlight.length > 0) {
            if (bstable.current) {
                bstable.current.selectionContext.selected = [];
                onSelectAll(false)
            }
        }

        let flightId = i.target.value
        setFlightId(flightId)

        let item = {
            id: i.target.value,
            status: 'sent',
            location: location,
        }
        dispatch(getSentFlightIds(item))
    }

    const handleChangeLocation = (loc) => {

        setLocation(loc)
        let item = {
            id: flightId,
            status: 'sent',
            location: loc,
        }
        dispatch(getSentFlightIds(item))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="გამოგზავნილი"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="button-items">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-primary w-xs waves-effect waves-light"
                                            disabled={props.logistic.preloaderAll || props.logistic.invoice}
                                            onClick={handleChangeStatusAll}
                                        >
                                            {
                                                props.logistic.preloaderAll === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }

                                            <span>  ჩამოსული (ყველა)</span>
                                        </Button>{" "}
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn btn-success w-lg waves-effect waves-light"
                                            disabled={props.logistic.preloaderChecked || props.logistic.invoice}
                                            onClick={handleChangeStatusChecked}

                                        >
                                            {
                                                props.logistic.preloaderChecked === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }
                                            <span>  ჩამოსული(მხოლოდ მონიშნული)</span>
                                        </Button>

                                        <Button

                                            type="button"
                                            color="info"
                                            className="btn btn-success w-lg waves-effect waves-light"
                                            disabled={props.logistic.invoice}
                                            onClick={handleCreteInvoice}
                                        >
                                            {
                                                props.logistic.invoice === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }
                                            <span>  ინვოისების შექმნა (არჩეული რეისის)</span>
                                        </Button>

                                        <FormGroup className="mb-3 p-lg-1">
                                            <Label htmlFor="validationCustom02">დოლარის კურსი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="rate"
                                                value={rate}
                                                onChange={e => setRate(e.target.value)}
                                            />
                                        </FormGroup>
                                        <div style={{marginLeft: '8px'}}>
                                            <div className="square-switch">
                                                <input
                                                    type="checkbox"
                                                    id="square-switch1"
                                                    switch="none"
                                                    checked={sq1}
                                                    onChange={() => {
                                                        setsq1(!sq1)
                                                    }}
                                                />
                                                <label
                                                    htmlFor="square-switch1"
                                                    data-on-label="On"
                                                    data-off-label="Off"
                                                />
                                                <span
                                                    style={{position: 'relative', bottom: '16px', marginLeft: '10px'}}
                                                >სმესების და მაილების დაგზავნა </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col>
                                            <hr/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <label className="col-md-2 col-form-label">რეისი</label>
                                            <select
                                                value={flightId}
                                                onChange={handleChangeFlight}
                                                className="form-control">
                                                {
                                                    props.products.sentFlight.map((item, key) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                data-country={item.countryId}
                                                                value={item._id}>რეისი: #{item.flightId} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <br/>
                                            <div className="button-items">
                                                <Button color="success"
                                                        onClick={handleChangeLocation.bind(this, 'tbilisi')}
                                                        className={`btn-soft-success waves-effect waves-light ${location === 'tbilisi' ? 'active' : ''}`}>თბილისი <span
                                                    className="badge bg-soft-danger font-size-12">{props.products.productsUserCreaterCount}</span></Button>{" "}
                                                <Button color="info"
                                                        onClick={handleChangeLocation.bind(this, 'kutaisi')}
                                                        className={`btn-soft-info waves-effect waves-light  ${location === 'kutaisi' ? 'active' : ''}`}>ქუთაისი
                                                    <span
                                                        className="badge bg-soft-danger font-size-12">{props.products.productsAdminCreaterCount}</span></Button>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col lg={12}>
                            {
                                props.products.listPreloader
                                    ?
                                    <Card style={{padding:'20px'}}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                                            <ClipLoader color={'#5b73e8'} loading={true} size={60} />
                                        </div>
                                    </Card>
                                    : <ObjectColumns
                                        bstable={bstable}
                                        onRowSelect={onRowSelect}
                                        onSelectAll={onSelectAll}
                                        location={location}
                                        logisticProducts={props.products.products}
                                    />
                            }

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic,
        information
    } = state
    return {
        products,
        logistic,
        information
    }
};
export default withRouter(connect(mapStateToProps)(Sent));
