import {
    LOGIN_REQUEST,
    LOGIN_REQUEST_SUCCESS,
    CHECK_ADMIN_REQUEST,CHECK_ADMIN_INFORMATION_REQUEST,
    CHECK_ADMIN_REQUEST_SUCCESS, LOGOUT_ADMIN_REQUEST
} from "./actionTypes"

export const loginAdmin = (data) => ({
    type: LOGIN_REQUEST,
    payload: data,
})
export const loginAdminSuccess = data => ({
    type: LOGIN_REQUEST_SUCCESS,
    payload: data,
})

export const checkAuthAdminInformation = data => ({
    type: CHECK_ADMIN_REQUEST,
    payload: data,
})
export const checkAdminInformation = data => ({
    type: CHECK_ADMIN_INFORMATION_REQUEST,
    payload: data,
})

export const checkAuthAdminInformationSuccess = data => ({
    type: CHECK_ADMIN_REQUEST_SUCCESS,
    payload: data,
})

export const logOutAdmin = () => ({
    type: LOGOUT_ADMIN_REQUEST,
})
