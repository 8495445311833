import {
    GET_POPAP_STATUS_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    result :{
        _id :'',
        titleKa :'',
        titleRu :'',
        titleEn :'',
    }
}

const Pages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_POPAP_STATUS_REQUEST_SUCCESS:

            if (action.callBack.result) {
                return {
                    ...state,
                    result : action.callBack.result,
                }
            }


        default:
            return state
    }
}

export default Pages
