import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, FormGroup, Label, Modal, Row,} from "reactstrap";
import {notification} from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {countriesEn} from '../../helpers/status'
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import {getLogisticProduct} from "../../store/products/actions";
import {updateCourier} from "../../store/courier/actions";


const modalError = type => {
    notification[type]({
        message: 'Error',
        description: 'Fill in all fields',
    });
};
const modalSuccess = type => {
    notification[type]({
        message: 'Success',
        description: 'The operation was successfully performed',
    });
};


const Warehouse = props => {
    const dispatch = useDispatch();
    const [result, setResult] = useState('')
    const [AXID, setAXID] = useState('')
    const [trackingId, setTrackingId] = useState('')
    const [weight, setWeight] = useState('')
    const [totalWeight, setTotalWeight] = useState('')
    const [productCount, setProductCount] = useState('')

    const [nameEn, setNameEn] = useState('')
    const [lastNameEn, setLastNameEn] = useState('')
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [array, setArray] = useState([])
    const [product, setProduct] = useState({})

    useEffect(()=>{

        if (props.auth.isAuthenticated) {

            let item = {
                country: props.auth.admin.country
            }
            getsData(item)
        }


    },[props.auth])

    const handleAddAdmin = () => {
        if (AXID && trackingId && weight) {
            let item = {
                country: props.auth.admin.country,
                AXID : AXID.toUpperCase(),
                trackingId,
                weight,
                nameEn,
                lastNameEn
            }
           fetchData(item)
        } else {
            modalError('error')
        }
    }

    const fetchData = async (item) => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/warehouse/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(item)
            });
            const json = await response.json();
            if (json.success) {
                modalSuccess('success')
                let item = {
                    country: props.auth.admin.country
                }
                getsData(item)
                setAXID('')
                setTrackingId('')
                setWeight('')
                setNameEn('')
                setLastNameEn('')
            } else {
                modalError('error')
            }

        } catch (error) {
            modalError('error')
            console.error('Error fetching data:', error);
        }
    };
    const updateData = async (item) => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/warehouse/updateProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(item)
            });
            const json = await response.json();
            if (json.success) {
                modalSuccess('success')
                setmodal_xlarge(!modal_xlarge)
                let item = {
                    country: props.auth.admin.country
                }
                getsData(item)
            } else {
                modalError('error')
            }

        } catch (error) {
            modalError('error')
            console.error('Error fetching data:', error);
        }
    };


    const getsData = async (item) => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/warehouse/gets/${item.country}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const json = await response.json();
            if (json.result) {
                setArray(json.product)
                setTotalWeight(json.totalWeight)
                setProductCount(json.productCount)

            } else {
                modalError('error')
            }

        } catch (error) {
            modalError('error')
            console.error('Error fetching data:', error);
        }
    };

    const getData = async (item) => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/warehouse/get/${item.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const json = await response.json();
            if (json.result) {
                setProduct(json.product)

            } else {
                modalError('error')
            }

        } catch (error) {
            modalError('error')
            console.error('Error fetching data:', error);
        }
    };




    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)

        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            getData(item)
          //  dispatch(getLogisticProduct(item))
        }
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            userInformation: {...prevState.userInformation, [name]: value}
        }));
    };


    const handleUpdateInformation = () => {
        let item = {
            _id: product._id,
            weight : product.weight,
            productOrderId : product.productOrderId,
            AXID : product.userInformation.AXID,
            nameEn : product.userInformation.nameEn,
            lastNameEn : product.userInformation.lastNameEn,
        }

        updateData(item)

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs breadcrumbItem="Warehouse"/>
                    {
                        props.auth.isAuthenticated
                            ?
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col xl={12}>
                                            <h2>{  countriesEn(props.auth.admin.country)}</h2>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label>Tracking ID</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={trackingId}
                                                    onChange={(e) => setTrackingId(e.target.value)}
                                                    name="trackingId"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label>AXID</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={AXID}
                                                    onChange={(e) => setAXID(e.target.value)}
                                                    name="AXID"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">User Name</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={nameEn}
                                                    onChange={(e) => setNameEn(e.target.value)}
                                                    name="nameEn"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">User Last Name</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={lastNameEn}
                                                    onChange={(e) => setLastNameEn(e.target.value)}
                                                    name="lastNameEn"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label>weight</Label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={weight}
                                                    onChange={(e) => setWeight(e.target.value.replace(/[^0-9\.]/g, ''))}
                                                    name="weight"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" style={{paddingTop: '27px'}}>

                                            <Button
                                                onClick={handleAddAdmin}
                                                color="primary"
                                                className="btn btn-primary waves-effect waves-light"
                                            >
                                                + Add
                                            </Button>
                                        </Col>
                                    </Row>

                                    {
                                        array
                                        ? <>
                                            <Row>
                                                <Col md="12">
                                                    <h6><strong>Total Quantity:</strong> {productCount}</h6>
                                                </Col>
                                                <Col md="12">
                                                    <h6><strong>Total Weight</strong>: {
                                                        totalWeight && totalWeight.toFixed(2)}</h6>
                                                </Col>
                                                <Col md="12">
                                                    <hr/>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <h6>Tracking ID</h6>
                                                </Col>

                                                <Col md="2">
                                                    <h6>AXID</h6>
                                                </Col>

                                                <Col md="2">
                                                    <h6>Name</h6>
                                                </Col>

                                                <Col md="2">
                                                    <h6>weight</h6>
                                                </Col>
                                                <Col md="2">
                                                    <h6>Action</h6>
                                                </Col>

                                                {
                                                    array.map((item, key) =>{
                                                        return (
                                                            <React.Fragment key={key}>
                                                                <Col md="4">
                                                                    <h6>{item.productOrderId}</h6>
                                                                </Col>
                                                                <Col md="2">
                                                                    <h6>{item.userInformation.AXID}</h6>
                                                                </Col>
                                                                <Col md="2">
                                                                    <h6>{item.userInformation.nameEn} {item.userInformation.lastNameEn}</h6>
                                                                </Col>
                                                                <Col md="2">
                                                                    <h6>{item.weight}</h6>
                                                                </Col>
                                                                <Col md="2">
                                                                       <div
                                                                           style={{cursor: 'pointer', float: 'left'}}
                                                                           onClick={() => {
                                                                               tog_large(item._id, key)
                                                                           }}
                                                                           className="px-3 text-primary">
                                                                             <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                                                                       </div>
                                                                </Col>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }


                                            </Row>

                                            </>
                                            :null
                                    }
                                </CardBody>
                            </Card>

                            : <>Error</>
                    }

                    <Modal size="xl" isOpen={modal_xlarge}  toggle={() => { tog_large()  }} >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myExtraLargeModalLabel"
                            >
                                თრექინგ კოდი:
                                {product?.productOrderId}
                                {/*{ props.product.productOrderId}*/}
                                {/*{product.productOrderId}*/}
                            </h5>
                            <button
                                onClick={() => {
                                    setmodal_xlarge(false)
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                           {
                                !isEmpty(product._id)
                                    ?
                                    <>
                                        <Row>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">თრექინგ კოდი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.productOrderId}
                                                        onChange={handleChange}
                                                        name="productOrderId"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom02">მყიდველის სახელი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.userInformation.nameEn}
                                                        onChange={handleChangeInformation}
                                                        name="nameEn"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom02">მყიდველი გვარი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.userInformation.lastNameEn}
                                                        onChange={handleChangeInformation}
                                                        name="lastNameEn"
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">AXID</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.userInformation.AXID}
                                                        onChange={handleChangeInformation}
                                                        name="AXID"
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom02">წონა</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={product.weight}
                                                        onChange={handleChange}
                                                        name="weight"
                                                    />
                                                </FormGroup>
                                            </Col>


                                        </Row>
                                        <Row>

                                            <Col md="3">
                                                <Button
                                                    onClick={handleUpdateInformation}
                                                    color="primary"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    დამახსოვრება
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                    : null
                            }

                        </div>
                    </Modal>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        auth,
        products
    } = state
    return {
        auth,
        products
    }
};
export default withRouter(connect(mapStateToProps)(Warehouse));
