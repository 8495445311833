import {call, put, takeEvery} from "redux-saga/effects";
import {
    DELETE_USER_REQUEST,
    DELETE_USER_REQUEST_SUCCESS,
    GET_USER_REQUEST,
    GET_USER_REQUEST_SUCCESS,
    GET_USERS_REQUEST,
    GET_USERS_REQUEST_SUCCESS,
    SEARCH_USER_REQUEST,
    UPDATE_USER_BALANCE_REQUEST, UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST,
    UPDATE_USER_REQUEST,
    UPDATE_USER_REQUEST_SUCCESS,
} from "./actionTypes";
import {notification} from "antd";


const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};


const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getUsersSaga({payload}) {
    try {
        const callBack = yield call(getUsersSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_USERS_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const getUsersSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/gets/${item.type}/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getUserSaga({payload}) {
    try {
        const callBack = yield call(getUserSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_USER_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const getUserSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* deleteUserSaga({payload}) {
    try {
        const callBack = yield call(deleteUserSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: DELETE_USER_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const deleteUserSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/delete`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}

function* updateUserSaga({payload}) {
    try {
        const callBack = yield call(updateUserSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: UPDATE_USER_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const updateUserSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/update`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}

function* updateUserBalanceSaga({payload}) {
    try {
        const callBack = yield call(updateUserBalanceSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: UPDATE_USER_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const updateUserBalanceSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/updateBalance`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}

function* updateUserIndividualTariffSaga({payload}) {
    try {
        const callBack = yield call(updateUserIndividualTariffSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: UPDATE_USER_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const updateUserIndividualTariffSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/tariffs/createIndividual`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}

function* searchUserSaga({payload}) {
    try {
        const callBack = yield call(searchUserSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_USERS_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const searchUserSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/users/searchUser/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* productsSaga() {
    yield takeEvery(GET_USERS_REQUEST, getUsersSaga)
    yield takeEvery(GET_USER_REQUEST, getUserSaga)
    yield takeEvery(UPDATE_USER_REQUEST, updateUserSaga)
    yield takeEvery(UPDATE_USER_BALANCE_REQUEST, updateUserBalanceSaga)
    yield takeEvery(UPDATE_USER_INDIVIDUAL_TARIFF_REQUEST, updateUserIndividualTariffSaga)
    yield takeEvery(DELETE_USER_REQUEST, deleteUserSaga)
    yield takeEvery(SEARCH_USER_REQUEST, searchUserSaga)
}
export default productsSaga
