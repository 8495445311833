import {GET_ADMIN_REQUEST, CREATE_ADMIN_REQUEST, DELETE_ADMIN_REQUEST} from "./actionTypes";

export const getAdmins = () => ({
    type: GET_ADMIN_REQUEST
})

export const createAdmin = (data) => ({
    type: CREATE_ADMIN_REQUEST,
    payload :data
})

export const deleteAdmin = (data) => ({
    type: DELETE_ADMIN_REQUEST,
    payload :data
})
