import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ACCOUNTENT_REQUEST, GET_ACCOUNTENT_REQUEST_SUCCESS,
  GET_INVOICES_ALL_REQUEST,
  GET_INVOICES_ALL_REQUEST_SUCCESS,
  GET_INVOICES_REQUEST,
  GET_INVOICES_REQUEST_SUCCESS,
  INVOICES_NULL
} from "./actionTypes"


function* getInvoicesSaga({payload}) {
  try {
    const callBack = yield call(createInvoiceSagaAsync, payload)
    if (callBack.result){
      yield put({type: GET_INVOICES_REQUEST_SUCCESS, callBack})
    }else {
      yield put({type: INVOICES_NULL})
    }

  } catch (e) {
    console.error(e);
  }
}

const createInvoiceSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")

  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/invoice/get/${item.flightId}/${item.AXID}/${item.branch}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${jwt}`,
    },
  })
  return await response.json()
}

function* getInvoicesAllSaga({payload}) {
  try {
    const callBack = yield call(getInvoicesAllSagaAsync, payload)
    if (callBack){
     yield put({type: GET_INVOICES_ALL_REQUEST_SUCCESS, callBack})
    }

  } catch (e) {
    console.error(e);
  }
}

const getInvoicesAllSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/invoice/getAll/${item.skip}/${item.limit}/${item.flightId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${jwt}`,
    },
  })
  return await response.json()
}


function* getAccountantSaga({payload}) {
  try {
    const callBack = yield call(getAccountantSagaAsync, payload)
    if (callBack){
     yield put({type: GET_ACCOUNTENT_REQUEST_SUCCESS, callBack})
    }

  } catch (e) {
    console.error(e);
  }
}

const getAccountantSagaAsync = async (item) => {
  let jwt = localStorage.getItem("token")
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/invoice/accountant/${item.skip}/${item.limit}/${item.flightId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${jwt}`,
    },
  })
  return await response.json()
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES_REQUEST, getInvoicesSaga)
  yield takeEvery(GET_INVOICES_ALL_REQUEST, getInvoicesAllSaga)
  yield takeEvery(GET_ACCOUNTENT_REQUEST, getAccountantSaga)
}

export default invoiceSaga
