export const PRELOADER_ON = "PRELOADER_ON"
export const PRELOADER_OFF = "PRELOADER_OFF"
export const GET_FLIGHT_REQUEST = "GET_FLIGHT_REQUEST"
export const GET_FLIGHT_REQUEST_SUCCESS = "GET_FLIGHT_REQUEST_SUCCESS"

export const GET_FLIGHTS_REQUEST = "GET_FLIGHTS_REQUEST"
export const GET_FLIGHTS_REQUEST_SUCCESS = "GET_FLIGHTS_REQUEST_SUCCESS"

export const UPDATE_FLIGHT_REQUEST = "UPDATE_FLIGHT_REQUEST"
export const UPDATE_FLIGHT_REQUEST_SUCCESS = "UPDATE_FLIGHT_REQUEST_SUCCESS"
