import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {Button, Card, Col, FormGroup, Label, Modal, Row,Input} from "reactstrap"
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Moment from 'react-moment';

import Nulled from "../../components/Null/Nulled";
import {city} from '../../helpers/status'
import {Link, withRouter} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteProduct, updateProduct} from "../../store/products/actions";
import {deleteCourier, getCourier, updateCourier} from "../../store/courier/actions";
import Courier from "../../store/courier/reducer";

const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})
    const [result, setResult] = useState({})
    const [cityS, setCityS] = useState('_TBILISI_')
    const [sq1, setsq1] = useState(false)

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        setId(id)
        setKey(key)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getCourier(item))
        }
    }

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteCourier(item))
        setconfirm_alert(false)
    }

    useEffect(()=>{
        setResult(props.Courier.result)
        setsq1(props.Courier.result?.thirdsUser)
        setCityS(props.Courier.result?.city)
    },[props.Courier.result._id])


    const handleChange = e => {
        const {name, value} = e.target;
        setResult(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateInformation = () => {
        let item = {
            key,
            result : {
                _id : result._id,
                address : result.address,
                branch : result.branch,
                city : cityS,
                name : result.name,
                phone : result.phone,
                thirdsUser : sq1,
            }
        }
        dispatch(updateCourier(item))
        setmodal_xlarge(false)
    }

    const Columns = [
        {
            dataField: "AXID",
            text: "AXID",
            sort: true,
        },
        {
            dataField: "flightId",
            text: "რეისი",
            sort: true,
        },
        {
            dataField: "city",
            text: "ქალაქი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        city(row.city)
                    }
                </>
            ),
        },
        {
            dataField: "address",
            text: "მისამართი",
            sort: true,
        },
        {
            dataField: "name",
            text: "სახელი",
            sort: true,
        },
        {
            dataField: "phone",
            text: "ტელეფონი",
            sort: true,
        },
        {
            dataField: "thirdsUser",
            text: "მესამე პირი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.thirdsUser === true || row.thirdsUser === 'true'
                            ? <> მესამე პირი </>
                            : null
                    }
                </>
            ),
        },
        {
            dataField: "createdAt",
            text: "თარიღი",
            formatter: (cellContent, row, key) => (
                <>
                    <Moment format="YYYY-MM-DD  h:mm:ss"
                            date={row.createdAt}/>
                </>
            ),
        },
        {

            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <span>
                     <span
                         style={{cursor: 'pointer', float: 'left'}}
                         onClick={() => {
                             tog_large(row._id, key)
                         }}
                         className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>

                </span>
                    <span>
                   <FeatherIcon
                       style={{cursor: 'pointer', float: 'left'}}
                       onClick={() => {
                           handleOpenAlertModal(row._id, key)
                       }}
                       icon="trash" className="icon-xs icon me-2 text-danger"/>
                </span>

                </>
            ),
        },

    ]


    return (
        <>
            {
                !isEmpty(props.array)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                keyField="_id"
                                data={props.array}
                                columns={Columns}
                            />
                        </Card>

                    </>
                    : <Nulled/>
            }


            <Modal size="xl" isOpen={modal_xlarge}
                   toggle={() => {
                       tog_large()
                   }}
            >
                {
                    result._id
                        ?
                        <>
                            <div className="modal-header">
                                <h5
                                    className="modal-title mt-0"
                                    id="myExtraLargeModalLabel"
                                >
                                    ID კოდი: {result._id}
                                </h5>
                                <button
                                    onClick={() => {
                                        setmodal_xlarge(false)
                                    }}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={result.name}
                                                onChange={handleChange}
                                                name="name"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={result.phone}
                                                onChange={handleChange}
                                                name="phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <div className="form-check">
                                                <Input
                                                    checked={sq1}
                                                    onChange={() => {
                                                        setsq1(!sq1)
                                                    }}
                                                    type="checkbox" className="form-check-input" id="formrow-customCheck"/>
                                                <Label className="form-check-label" htmlFor="formrow-customCheck">
                                                    ჩაიბარებს მესამე პირი
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <label
                                            className='bolder-label'>   ქალაქი </label>
                                        <select
                                            onChange={(e) => setCityS(e.target.value)}
                                            className="form-control form-control-border">
                                            <option value="_TBILISI_">_TBILISI_</option>
                                            <option value="_KUTAISI_">_KUTAISI_</option>
                                            <option value="_BATUMI_">_BATUMI_</option>
                                            <option value="_KOBULETI_">_KOBULETI_</option>
                                            <option
                                                value="_ABASTUMANI_">_ABASTUMANI_</option>
                                            <option value="_ABASHA_">_ABASHA_</option>
                                            <option value="_ADIGENI_">_ADIGENI_</option>
                                            <option
                                                value="_AMBROLAURI_">_AMBROLAURI_</option>
                                            <option value="_ANAKLIA_">_ANAKLIA_</option>
                                            <option value="_ASPINDZA_">_ASPINDZA_</option>
                                            <option
                                                value="_AKHALGORI_">_AKHALGORI_</option>
                                            <option
                                                value="_AKHALKALAKI_">_AKHALKALAKI_</option>
                                            <option
                                                value="_AKHALTSIKHE_">_AKHALTSIKHE_</option>
                                            <option value="_AKHMETA_">_AKHMETA_</option>
                                            <option
                                                value="_BAKURIANI_">_BAKURIANI_</option>
                                            <option value="_BAXMARO_">_BAXMARO_</option>
                                            <option value="_BARDADI_">_BARDADI_</option>
                                            <option value="_BOLNISI_">_BOLNISI_</option>
                                            <option value="_BORJOMI_">_BORJOMI_</option>
                                            <option value="_CAGVERI_">_CAGVERI_</option>
                                            <option
                                                value="_GARDABANI_">_GARDABANI_</option>
                                            <option value="_GONIO_">_GONIO_</option>
                                            <option value="_GORI_">_GORI_</option>
                                            <option
                                                value="_GRIGOLETI_">_GRIGOLETI_</option>
                                            <option value="_GUDAURI_">_GUDAURI_</option>
                                            <option value="_GURJAANI_">_GURJAANI_</option>
                                            <option
                                                value="_DEDOFLISCKARO_">_DEDOFLISCKARO_</option>
                                            <option value="_DMANISI_">_DMANISI_</option>
                                            <option value="_DUSETI_">_DUSETI_</option>
                                            <option
                                                value="_ZESTAFONI_">_ZESTAFONI_</option>
                                            <option value="_ZUGDIDI_">_ZUGDIDI_</option>
                                            <option value="_VANI_">_VANI_</option>
                                            <option
                                                value="_TETRIWYARO_">_TETRIWYARO_</option>
                                            <option value="_TELAVI_">_TELAVI_</option>
                                            <option value="_TERJOLA_">_TERJOLA_</option>
                                            <option value="_TIANETI_">_TIANETI_</option>
                                            <option value="_KASPI_">_KASPI_</option>
                                            <option value="_KAJRETI_">_KAJRETI_</option>
                                            <option value="_KVARIATI_">_KVARIATI_</option>
                                            <option value="_KOJORI_">_KOJORI_</option>
                                            <option value="_LAGODEXI_">_LAGODEXI_</option>
                                            <option value="_LANCXUTI_">_LANCXUTI_</option>
                                            <option value="_MARNEULI_">_MARNEULI_</option>
                                            <option value="_LENTEXI_">_LENTEXI_</option>
                                            <option value="_MARTVILI_">_MARTVILI_</option>
                                            <option value="_MCXETA_">_MCXETA_</option>
                                            <option value="_MESTIA_">_MESTIA_</option>
                                            <option
                                                value="_MCVANEKONCXI_">_MCVANEKONCXI_</option>
                                            <option
                                                value="_NINOWMINDA_">_NINOWMINDA_</option>
                                            <option value="_OZURGETI_">_OZURGETI_</option>
                                            <option value="_ONI_">_ONI_</option>
                                            <option value="_RUSTAVI_">_RUSTAVI_</option>
                                            <option value="_SAGAREJO_">_SAGAREJO_</option>
                                            <option value="_SAGURAMO_">_SAGURAMO_</option>
                                            <option value="_SAIRME_">_SAIRME_</option>
                                            <option
                                                value="_SAMTREDIA_">_SAMTREDIA_</option>
                                            <option value="_SARFI_">_SARFI_</option>
                                            <option value="_SACXERE_">_SACXERE_</option>
                                            <option value="_SENAKI_">_SENAKI_</option>
                                            <option value="_SIRNARI_">_SIRNARI_</option>
                                            <option
                                                value="_STEFANWMIN_">_STEFANWMIN_</option>
                                            <option value="_SURAMI_">_SURAMI_</option>
                                            <option
                                                value="_TABAXMELA_">_TABAXMELA_</option>
                                            <option value="_TYIBULI_">_TYIBULI_</option>
                                            <option value="_UREKI_">_UREKI_</option>
                                            <option value="_FOTI_">_FOTI_</option>
                                            <option value="_QARELI_">_QARELI_</option>
                                            <option value="_QEDA_">_QEDA_</option>
                                            <option value="_YVARELI_">_YVARELI_</option>
                                            <option value="_SUAXEVI_">_SUAXEVI_</option>
                                            <option value="_CHAQVI_">_CHAQVI_</option>
                                            <option
                                                value="_COXATAURI_">_COXATAURI_</option>
                                            <option value="_CAGERI_">_CAGERI_</option>
                                            <option value="_CXOROWYU_">_CXOROWYU_</option>
                                            <option value="_WAVKISI_">_WAVKISI_</option>
                                            <option
                                                value="_WALENJIXA_">_WALENJIXA_</option>
                                            <option value="_WALKA_">_WALKA_</option>
                                            <option
                                                value="_TSEROVANI_">_TSEROVANI_</option>
                                            <option value="_WYALTUBO_">_WYALTUBO_</option>
                                            <option value="_WNORI_">_WNORI_</option>
                                            <option value="_WYNETI_">_WYNETI_</option>
                                            <option value="_WIATURA_">_WIATURA_</option>
                                            <option
                                                value="_XARAGAULI_">_XARAGAULI_</option>
                                            <option value="_XASHURI_">_XASHURI_</option>
                                            <option
                                                value="_XELVACAURI_">_XELVACAURI_</option>
                                            <option value="_XOBI_">_XOBI_</option>
                                            <option value="_XONI_">_XONI_</option>
                                            <option value="_XULO_">_XULO_</option>
                                        </select>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">მისამართი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={result.address}
                                                onChange={handleChange}
                                                name="address"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </>
                        :null
                }


            </Modal>
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>

        </>
    )
}

const mapStateToProps = state => {
    const {
        Courier,
    } = state
    return {
        Courier,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));

