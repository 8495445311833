import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import { Card  } from "reactstrap"
import {Link, withRouter} from "react-router-dom"
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Nulled from "../../components/Null/Nulled";
import moment from "moment";

const ObjectColumns = props => {

    const dispatch = useDispatch();
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [product, setProduct] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    const Columns = [
        {
            dataField: "_id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "type",
            text: "შესრულება",
            sort: true,
        },
        {
            text: "თანხა",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        parseFloat(row.amount).toFixed(2)

                    }
                </>
            ),
        },


        {
            dataField: "userAXID",
            text: "AXID",
            sort: true,
        },
        {
            dataField: "status",
            text: "სტატუსი",
            sort: true,
        },
        {
            text: "ძველი ნიშნული",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        parseFloat(row.oldStatus).toFixed(2)
                    }
                </>
            ),
        },
        {
            text: "ახალი ნიშნული",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        parseFloat(row.newStatus).toFixed(2)
                    }
                </>
            ),
        },

        {
            dataField: "destination",
            text: "დანიშნულება",
            sort: true,
        },
        {
            text: "თარიღი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        moment(row.createdAt).format('YYYY-MM-DD:HH:MM:SS')
                    }
                </>
            ),
        },

    ]


    return (
        <>
            {
                !isEmpty(props.array)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                _id={'_id'}
                                keyField="_id"
                                data={props.array}
                                columns={Columns}
                            />

                        </Card>

                    </>
                    : <Nulled/>
            }

        </>
    )
}

const mapStateToProps = state => {
    const {
        Merchant,
    } = state
    return {
        Merchant
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));

