import {
    COURIER_FINISH_REQUEST_SUCCESS, DELETE_COURIER_REQUEST_SUCCESS,
    GET_COURIER_REQUEST_SUCCESS, GET_COURIERS_REQUEST_SUCCESS, UPDATE_COURIER_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    result :{
        _id :''
    }
}

const Pages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_COURIER_REQUEST_SUCCESS:
            state.array[action.payload.key] = action.payload.result
            return {
                ...state,
            }
        case GET_COURIERS_REQUEST_SUCCESS:
            return {
                ...state,
                array :  action.callBack.couriers,
            }
        case GET_COURIER_REQUEST_SUCCESS:
            return {
                ...state,
                result :  action.callBack.result,
            }
        case DELETE_COURIER_REQUEST_SUCCESS:
            return {
                ...state,
                array: state.array.filter(todo => todo._id !== action.payload.id)
            }
        case COURIER_FINISH_REQUEST_SUCCESS:
            return {
                ...state,
                array :  [],
            }
        default:
            return state
    }
}

export default Pages
