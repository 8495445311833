import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_OFFICE_REQUEST,
    GET_OFFICE_REQUEST_SUCCESS,
    GET_OFFICES_REQUEST, GET_OFFICES_REQUEST_SUCCESS, UPDATE_OFFICES_REQUEST, UPDATE_OFFICES_REQUEST_SUCCESS,
} from "./actionTypes";


import {notification} from 'antd';

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};

function* getOfficesSaga({payload}) {
    try {
        const callBack = yield call(getOfficesSagaAsync)

        if (callBack){
            yield put({type: GET_OFFICES_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getOfficesSagaAsync = async () => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/office/gets`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
    })
    return await response.json()
}

function* getTariffSaga({payload}) {
    try {
        const callBack = yield call(getTariffSagaAsync, payload)

        if (callBack){
            yield put({type: GET_OFFICE_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getTariffSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/office/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
    })
    return await response.json()
}

function* updateTariffSaga({payload}) {
    try {
        const callBack = yield call(updateTariffSagaAsync, payload)
        if (callBack){
            yield put({type: UPDATE_OFFICES_REQUEST_SUCCESS, payload})
            modalSuccess('success');
        }
    } catch (e) {
        console.error(e);
    }
}

const updateTariffSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/office/update`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body : JSON.stringify(item)
    })
    return await response.json()
}


function* informationSaga() {
    yield takeEvery(GET_OFFICES_REQUEST, getOfficesSaga)
    yield takeEvery(GET_OFFICE_REQUEST, getTariffSaga)
    yield takeEvery(UPDATE_OFFICES_REQUEST, updateTariffSaga)
}

export default informationSaga
