import React  from "react"
import {connect} from "react-redux"
import {Row, Col,  Container, CardBody, Card} from "reactstrap"

import { withRouter, Link } from "react-router-dom"


// import images
import logo from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import {loginAdmin} from "../../store/auth/actions";



class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
    }
  }


  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }
  handleLogin () {
    let item = {
      email : this.state.email,
      password : this.state.password,
      history : this.props.history
    }
    this.props.dispatch(loginAdmin(item))
  }
  render() {
    return (
        <React.Fragment>

          <div className="account-pages my-5 pt-sm-5">
            <Container>

              <Row className="align-items-center justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card>

                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                 
                      </div>
                      <div className="p-2 mt-4">
                        <div className="mb-3">
                          <input
                              onChange={this.handleChange("email")}
                              name="email"
                              className="form-control"
                              placeholder="ადმინის სახელი"
                              type="email"
                              required
                          />
                        </div>

                        <div className="mb-3">
                          <input
                              onChange={this.handleChange("password")}
                              name="password"
                              className="form-control"
                              placeholder="პაროლი"
                              type="password"
                              required
                          />
                        </div>


                        <div className="mt-3">
                          <button
                              onClick={this.handleLogin.bind(this)}
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="button"
                          >
                            შესვლა
                          </button>
                        </div>

                      </div>
                    </CardBody>
                  </Card>

                </Col>
              </Row>

            </Container>
          </div>
        </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {
    auth,
  } = state
  return {
    auth
  }
};
export default withRouter(connect(mapStateToProps)(Login));
