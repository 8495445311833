import {
    UPDATE_CHANGE_STATUS_STEP_1,
    UPDATE_CHANGE_STATUS_ALL,
    UPDATE_CHANGE_STATUS_STEP_2_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_3_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_4_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_5_CHECKED,
    UPDATE_CHANGE_STATUS_STEP_6_CHECKED,
    CREATE_INVOICE_REQUEST, STEP_ONE_PRELOADER_OFF, PAYMENT_CHECKED_REQUEST
} from "./actionTypes"

export const changeStatusStep1All = (data) => ({
    type: UPDATE_CHANGE_STATUS_STEP_1,
    payload :data
})
export const changeStatusAll = (data) => ({
    type: UPDATE_CHANGE_STATUS_ALL,
    payload :data
})

export const changeStatusStep2Cheked = (data) => ({
    type: UPDATE_CHANGE_STATUS_STEP_2_CHECKED,
    payload :data
})


export const changeStatusStep3Cheked = (data) => ({
    type: UPDATE_CHANGE_STATUS_STEP_3_CHECKED,
    payload :data
})

export const changeStatusStep4Cheked = (data) => ({
    type: UPDATE_CHANGE_STATUS_STEP_4_CHECKED,
    payload :data
})


export const changeStatusStep5Cheked = (data) => ({
    type: UPDATE_CHANGE_STATUS_STEP_5_CHECKED,
    payload :data
})

export const changeStatusStep6Cheked = (data) => ({
    type: UPDATE_CHANGE_STATUS_STEP_6_CHECKED,
    payload :data
})
export const paymentChecked = (data) => ({
    type: PAYMENT_CHECKED_REQUEST,
    payload :data
})

export const createInvoice = (data) => ({
    type: CREATE_INVOICE_REQUEST,
    payload :data
})

export const stepOneResultOff = () => ({
    type: STEP_ONE_PRELOADER_OFF
})
