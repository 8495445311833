import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row, Card, Col, Modal, FormGroup, Label, Button,
} from "reactstrap"
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    deleteProduct,
    getLogisticProduct,
    nullModalProduct,
    updateProduct
} from "../../store/actions";

import Nulled from "../../components/Null/Nulled";
import {shop} from '../../helpers/status'

const rowStyle2 = (row, rowIndex) => {
    const style = {};
    if (row.readerStatus === true) {
        style.backgroundColor = '#c8e6c9';
    }

    return style;
};

const ObjectColumns = props => {
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [product, setProduct] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    useEffect(()=>{
        setData(props.logisticProducts)
    },[props.logisticProducts])
    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        setId(id)
        setKey(key)
        /*
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            setKey(key)
            dispatch(getLogisticProduct(item))
        }*/
    }


    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }


    const handleChange = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeInformation = e => {
        const {name, value} = e.target;
        setProduct(prevState => ({
            ...prevState,
            userInformation: {...prevState.userInformation, [name]: value}
        }));
    };
    useEffect(() => {
        if (modal_xlarge === true) {
            let item = {
                id: id
            }
            dispatch(getLogisticProduct(item))
            setProduct(props.products.product)
        } else {
            dispatch(nullModalProduct())
        }

    }, [modal_xlarge, props.products.product._id])


    const Columns = [

        {
            dataField: "custom",
            text: "გან.",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.custom === true
                            ?
                            <>
                                {
                                    row.clearance === true
                                        ? <span style={{color: 'red', fontWeight: "strong"}}>მსურს</span>
                                        : <span style={{color: 'red', fontWeight: "strong"}}>განს.</span>
                                }
                            </>
                            :
                            <>
                                {
                                    row.clearance === true
                                        ? <span style={{color: 'red', fontWeight: "strong"}}>მსურს</span>
                                        : <>---</>
                                }
                            </>
                    }
                </>
            ),
        },

        {
            dataField: "productOrderId",
            text: "თრექინგ კოდი",
            sort: true,
        },
        {
            dataField: "productName",
            text: "დასახელება",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        shop(row.productName)
                    }
                </>
            ),
        },
        {
            dataField: "quantity",
            text: "რაოდ.",
            sort: true,
        },
        {
            dataField: "status",
            text: "სტატუსი",
            formatter: (cellContent, row, key) => (
                <strong>
                    {row.status}
                </strong>
            ),
        },
        {
            dataField: "userInformation2.information.passportId",
            text: "პასპორტი/ორგ.ID",
            sort: true,
            formatter: (cellContent, row) => {
                let nameEn = row.userInformation2.information?.nameEn ||null;
                if (parseInt(row.userInformation2?.type) === 1) {
                    nameEn = row.userInformation2?.information?.passportId
                } else {
                    if (row.userInformation2?.information?.indEntrepreneur) {
                        nameEn = row.userInformation2?.information?.passportId
                    } else {
                        nameEn = row.userInformation2?.information?.orgId
                    }

                }
                return (
                    nameEn
                );
            },
        },

        {
            dataField: "userInformation2.information.nameEn",
            text: "მყიდველი",
            sort: true,
            formatter: (cellContent, row) => {
                let nameEn = row.userInformation2?.information?.nameEn;
                if (parseInt(row.userInformation2?.type) === 1) {
                    nameEn = row.userInformation2?.information?.nameEn + ' ' + row.userInformation2?.information?.lastNameEn
                } else {
                    if (row.userInformation2?.information?.indEntrepreneur) {
                        nameEn = row.userInformation2?.information?.nameEn + ' ' + row.userInformation2?.information?.lastNameEn
                    } else {
                        nameEn = row.userInformation2?.information?.LTDNameEn
                    }

                }
                return (
                    <strong>
                        {nameEn}
                    </strong>
                );
            },
        },


        {
            dataField: "userInformation.AXID",
            text: "AX კოდი",
            sort: true,
        },
        {
            dataField: "userInformation2.phone",
            text: "ტელეფონი",
            sort: true,
        },


        {
            dataField: "shop",
            sort: true,
            text: "მაღაზია",
            formatter: (cellContent, row) => (
                <>
                    {
                        row.shop === '_OTHER_SHOP_'
                            ? row.otherShop
                            : row.shop
                    }
                </>
            ),
        },

        {
            dataField: "price",
            text: "თანხა",
            sort: true,
        },
        {
            dataField: "currency",
            text: "ვალუტა",
            sort: true,
        },
        {
            dataField: "weight",
            text: "წონა",
            sort: true,
        },

        {
            dataField: "flightId",
            text: "რეისი",
            sort: true,
        },

        {
            dataField: "file",
            text: "ფაილი",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.file
                            ? <a
                                href={`${process.env.REACT_APP_UPLOADER_SERVER_URL}/uploads/invoices/${row.file}`}
                                target="_blank"
                                color="success" style={{width: '100%'}}
                                className="btn-sm waves-effect waves-light">
                                ინვოისი
                            </a>
                            : null
                    }


                </>
            ),
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    {
                        row.status === 'arrived' || row.status === 'sent' && row.flightId
                            ? <span>
                                 <Link to={`/logistic/invoice/${row.flightId}/${row.userInformation.AXID}`}
                                       className="px-3 text-primary"><FeatherIcon icon="paperclip"
                                                                                  className="icon-xs icon me-2 text-pending"/>
                                 </Link>
                               </span>
                            : null
                    }

                    <span>
                     <span
                         style={{cursor: 'pointer'}}
                         onClick={() => {
                             tog_large(row._id, key)
                         }}
                         className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>

                </span>
                    <span>
                   <FeatherIcon
                       style={{cursor: 'pointer'}}
                       onClick={() => {
                           handleOpenAlertModal(row._id, key)
                       }}
                       icon="trash" className="icon-xs icon me-2 text-danger"/>
                </span>
                </>
            ),
        },

    ]

    const handleUpdateInformation = () => {
        let item = {
            key,
            product
        }
        dispatch(updateProduct(item))
        setmodal_xlarge(false)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteProduct(item))
        setconfirm_alert(false)
    }
    return (
        <>
            {
                !isEmpty(data)
                    ?
                    <>
                        <Card>
                            <BootstrapTable
                                keyField="_id"
                                data={data}
                                columns={Columns}
                                selectRow={{
                                    mode: 'checkbox',
                                    clickToSelect: false,
                                    onSelect: props.onRowSelect,
                                    onSelectAll: props.onSelectAll,
                                }}
                                rowStyle={rowStyle2}
                                ref={props.bstable}
                            />
                           {/* <BootstrapTable
                                selectRow={{
                                    mode: 'checkbox', clickToSelect: false,
                                    onSelect: props.onRowSelect,
                                    onSelectAll: props.onSelectAll,
                                }}
                                rowStyle={rowStyle2}
                                ref={props.bstable}
                                _id={'_id'}
                                keyField="_id"
                                data={data}
                                columns={Columns}
                            />*/}
                        </Card>

                    </>
                    : <Nulled/>
            }

            <Modal size="xl" isOpen={modal_xlarge}
                   toggle={() => {
                       tog_large()
                   }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        თრექინგ კოდი: {product.productOrderId}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        !isEmpty(product._id)
                            ?
                            <>
                                <Row>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">თრექინგ კოდი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.productOrderId}
                                                onChange={handleChange}
                                                name="productOrderId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">დასახელება</Label>
                                            <select
                                                value={product.productName}
                                                onChange={handleChange}
                                                name="productName"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="_VARIOUS_ELECTRONIC_DEVICES_">სხვადასხვა ელექტრონული
                                                    მოწყობილებები
                                                </option>
                                                <option value="_BAGS_AND_HOLDERS">ჩანთები და ჩასადებები</option>
                                                <option value="_SHOES_">ფეხსაცმელი</option>
                                                <option value="_TELEPHONE_AND_NETWORK_DEVICES">ტელეფონი და ქსელური
                                                    მოწყობილობები
                                                </option>
                                                <option value="_CLOTHING_ALL_TYPES_OF_CLOTHING_">ტანსაცმელი, ყველა ტიპის
                                                    სამოსი
                                                </option>
                                                <option value="_FOOD_SUPPLEMENTS_">საკვები დანამატები</option>
                                                <option value="_TOYS_AND_SPORTS_EQUIPMENT_">სათამაშოები და სპორტული
                                                    ინვენტარი
                                                </option>
                                                <option value="_WATCHES_">საათები</option>
                                                <option value="_PERFUMERY_AND_COSMETICS_">პარფიუმერია და კოსმეტიკა
                                                </option>
                                                <option value="_PRINTED_PRODUCTS_BOOKS_BROCHURES_">ნაბეჭდი პროდუქცია,
                                                    წიგნები, ბროშურები
                                                </option>
                                                <option value="_MUSICAL_INSTRUMENTS_AND_THEIR_PARTS_">მუსიკალური
                                                    ინსტრუმენტები და მათი ნაწილები
                                                </option>
                                                <option value="_GLASSWARE_">მინის ნაწარმი</option>
                                                <option value="_MEDICATIONS_">მედიკამენტები</option>
                                                <option value="_COMPUTER_LAPTOP_AND_THEIR_PARTS_">კომპიუტერი, ლეპტოპი და
                                                    მათი ნაწილები
                                                </option>
                                                <option value="_TOOLS_AND_HAND_TOOLS_">იარაღები და ხელის ინსტრუმენტები
                                                </option>
                                                <option value="_LIGHTING_CHANDELIERS_LAMPS_HEADLIGHTS_">განათება,
                                                    ჭაღები, ლამპები, ფარები
                                                </option>
                                                <option value="_BIJOUTERIE_">ბიჟუტერია</option>
                                                <option value="_AUTO_PARTS_">ავტო ნაწილები</option>
                                                <option value="_VARIOUS_FINISHED_PRODUCTS_">სხვადასხვა მზა ნაწარმი
                                                </option>

                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">რაოდ</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.quantity}
                                                onChange={handleChange}
                                                name="quantity"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მყიდველის სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                disabled
                                                value={product.userInformation.nameEn}
                                                name="nameEn"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მყიდველი გვარი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.lastNameEn}
                                                disabled
                                                name="lastNameEn"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">AXID</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.AXID}
                                                onChange={handleChangeInformation}
                                                name="AXID"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.phone}
                                                onChange={handleChangeInformation}
                                                name="phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">პასპორტი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.userInformation.passportId}
                                                onChange={handleChangeInformation}
                                                name="passportId"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">მაღაზია</Label>
                                            <select
                                                value={product.shop}
                                                onChange={handleChange}
                                                name="shop"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="TAOBAO.COM">TAOBAO.COM</option>
                                                <option value="EBAY.COM">EBAY.COM</option>
                                                <option value="ALIEXPRESS.COM">ALIEXPRESS.COM</option>
                                                <option value="ALIBABA.COM">ALIBABA.COM</option>
                                                <option value="TMALL.COM">TMALL.COM</option>
                                                <option value="other">სხვა მაღაზია</option>
                                            </select>

                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">თანხა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.price}
                                                onChange={handleChange}
                                                name="price"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">ვალუტა</Label>
                                            <select
                                                value={product.currency}
                                                onChange={handleChange}
                                                name="currency"
                                                className="form-control">
                                                <option value="">--</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="CNY">CNY</option>
                                                <option value="TRY">TRY</option>
                                                <option value="GEL">GEL</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">წონა</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.weight}
                                                onChange={handleChange}
                                                name="weight"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">რეისი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.flightId}
                                                onChange={handleChange}
                                                name="flightId"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">სტატუსი </Label>
                                            <select
                                                value={product.status}
                                                onChange={handleChange}
                                                name="status"
                                                className="form-control">
                                                <option value='waiting'>waiting</option>
                                                <option value='stock'>stock</option>
                                                <option value='sent'>sent</option>
                                                <option value='arrived'>arrived</option>
                                                <option value='obtain'>obtain</option>
                                                <option value='lost'>lost</option>
                                                <option value='other'>other</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    {
                                        props.auth.admin.role === 1 && <Col md="3">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">გადახდა </Label>
                                                <select
                                                    value={product.state}
                                                    onChange={handleChange}
                                                    name="state"
                                                    className="form-control">
                                                    <option value='unpaid'>არა გადახდილი</option>
                                                    <option value='paid'>გადახდილია</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    }

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label>განბაჟება </Label>
                                            <select
                                                value={product.custom}
                                                onChange={handleChange}
                                                name="custom"
                                                className="form-control">
                                                <option value={false}>არა განსაბაჟებელი</option>
                                                <option value={true}>განსაბაჟებელია</option>
                                            </select>
                                        </FormGroup>
                                    </Col>

                                    <Col md="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">ტარიფი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={product.tariff}
                                                onChange={handleChange}
                                                name="tariff"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                        <div className="mb-3">
                                            <Label className="form-label"> ფილიალი </Label>
                                            <select
                                                value={product.branch}
                                                onChange={handleChange}
                                                name="branch"
                                                className="form-control form-control-border">
                                                <option value="gldani">გლდანი</option>
                                                <option value="didube">დიდუბე</option>
                                                <option value="isani">ისანი</option>
                                                <option value="kutaisi_1">kutaisi_1</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="3">
                                        <Button
                                            onClick={handleUpdateInformation}
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light"
                                        >
                                            დამახსოვრება
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }

                </div>
            </Modal>
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}

const mapStateToProps = state => {
    const {
        auth,
        products,
    } = state
    return {
        auth,
        products,
    }
};
export default withRouter(connect(mapStateToProps)(ObjectColumns));

