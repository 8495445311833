export const UPDATE_CHANGE_STATUS_ALL = "UPDATE_CHANGE_STATUS_ALL"

export const UPDATE_CHANGE_STATUS_STEP_1 = "UPDATE_CHANGE_STATUS_STEP_1"

export const UPDATE_CHANGE_STATUS_STEP_2 = "UPDATE_CHANGE_STATUS_STEP_2"
export const UPDATE_CHANGE_STATUS_STEP_2_CHECKED = "UPDATE_CHANGE_STATUS_STEP_2_CHECKED"

export const UPDATE_CHANGE_STATUS_STEP_3 = "UPDATE_CHANGE_STATUS_STEP_3"
export const UPDATE_CHANGE_STATUS_STEP_3_CHECKED = "UPDATE_CHANGE_STATUS_STEP_3_CHECKED"

export const UPDATE_CHANGE_STATUS_STEP_4 = "UPDATE_CHANGE_STATUS_STEP_4"
export const UPDATE_CHANGE_STATUS_STEP_4_CHECKED = "UPDATE_CHANGE_STATUS_STEP_4_CHECKED"

export const UPDATE_CHANGE_STATUS_STEP_5 = "UPDATE_CHANGE_STATUS_STEP_5"
export const UPDATE_CHANGE_STATUS_STEP_5_CHECKED = "UPDATE_CHANGE_STATUS_STEP_5_CHECKED"

export const UPDATE_CHANGE_STATUS_STEP_6_CHECKED = "UPDATE_CHANGE_STATUS_STEP_6_CHECKED"

export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST"
export const CREATE_INVOICE_REQUEST_SUCCESS = "CREATE_INVOICE_REQUEST_SUCCESS"

export const PAYMENT_CHECKED_REQUEST = "PAYMENT_CHECKED_REQUEST"
export const PAYMENT_CHECKED_REQUEST_SUCCESS = "PAYMENT_CHECKED_REQUEST_SUCCESS"

export const PRELOADER_ON = "PRELOADER_ON"
export const PRELOADER_OFF = "PRELOADER_OFF"
export const PRELOADER_INVOICE_ON = "PRELOADER_INVOICE_ON"
export const PRELOADER_INVOICE_OFF = "PRELOADER_INVOICE_OFF"
export const STEP_ONE_PRELOADER_ON = "STEP_ONE_PRELOADER_ON"
export const STEP_ONE_PRELOADER_OFF = "STEP_ONE_PRELOADER_OFF"
export const PRELOADER_CHECKED_ON = "PRELOADER_CHECKED_ON"
export const PRELOADER_CHECKED_OFF = "PRELOADER_CHECKED_OFF"
