import React from "react"
import { Redirect } from "react-router-dom"


//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Warehouse from "../pages/Warehouse/warehouse"

// Pages Calendar
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"


import CourierPage from "../pages/Courier/index"
import CourierList from "../pages/Courier/list"

import LogisticWaiting from "../pages/Logistic/Waiting"
import LogisticStock from "../pages/Logistic/Stock"
import SentStock from "../pages/Logistic/Sent"
import ArrivedStock from "../pages/Logistic/Arrived"
import Search from "../pages/Logistic/Search"
import Obscure from "../pages/Logistic/Obscure"

import Obtained from "../pages/Logistic/Obtained"
import Multi from "../pages/Logistic/Multi"
import Giveaway from "../pages/Logistic/Giveaway"
import Invoice from "../pages/Invoices/Invoice"
import InvoiceAll from "../pages/Invoices/InvoiceAll"
import Accountant from "../pages/Invoices/accountant"
import Sorting from "../pages/Logistic/Sorting"
import SortingNew from "../pages/Logistic/SortingNew"

import Terms from "../pages/Pages/Terms"
import Tariffs from "../pages/Tariffs/index"
import Offices from "../pages/Office/index"

//Merhchant
import Transactions from "../pages/merchant/transactions"
import UserTransactions from "../pages/merchant/userTransactions"

import Flight from "../pages/Flight/index"
import Excel from "../pages/Flight/Excel"

import CreateNews from "../pages/News/create"
import ListNews from "../pages/News/list"
import DetailNews from "../pages/News/detail"

import PopapCreate from "../pages/Popap/create"
import NotificationCreate from "../pages/Notification/create"


import Admins from "../pages/Admin/Index"
import Users from "../pages/User/Index"
import UserSearch from "../pages/User/Search"


const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/warehouse", component: Warehouse },

  { path: "/courier", component: CourierPage },
  { path: "/courierlist", component: CourierList },



  { path: "/logistic/waiting", component: LogisticWaiting },
  { path: "/logistic/stock", component: LogisticStock },
  { path: "/logistic/sent", component: SentStock },
  { path: "/logistic/arrived", component: ArrivedStock },
  { path: "/logistic/search/:id", component: Search },
  { path: "/logistic/invoice/:flightId/:AXID/:branch?", component: Invoice },
  { path: "/logistic/invoiceAll/:flightId", component: InvoiceAll },
  { path: "/logistic/accountant/:flightId", component: Accountant },


  { path: "/logistic/obtained", component: Obtained },
  { path: "/logistic/giveaway", component: Giveaway },
  { path: "/logistic/multi", component: Multi },
  { path: "/logistic/sorting", component: Sorting },
  { path: "/logistic/SortingNew", component: SortingNew },
  { path: "/logistic/obscure", component: Obscure },

  { path: "/pages/terms", component: Terms },
  { path: "/pages/tariffs", component: Tariffs },
  { path: "/pages/offices", component: Offices },

  { path: "/news/create", component: CreateNews },
  { path: "/news/list", component: ListNews },
  { path: "/news/detail/:id", component: DetailNews },

  { path: "/popap/create", component: PopapCreate },
  { path: "/notification/create", component: NotificationCreate },

  //  merchant
  { path: "/merchant/transactions", component: Transactions },
  { path: "/merchant/user/:id", component: UserTransactions },



  { path: "/flight", component: Flight },
  { path: "/Excel/:id", component: Excel },

  { path: "/admins", component: Admins },
  { path: "/users", component: Users },
  { path: "/user/:id", component: UserSearch },

  //chat

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

    // Authentication Inner
]

export { userRoutes, authRoutes }
