export const GET_COURIER_REQUEST = "GET_COURIER_REQUEST"
export const GET_COURIER_REQUEST_SUCCESS = "GET_COURIER_REQUEST_SUCCESS"

export const GET_COURIERS_REQUEST = "GET_COURIERS_REQUEST"
export const GET_COURIERS_REQUEST_SUCCESS = "GET_COURIERS_REQUEST_SUCCESS"

export const GET_OLD_COURIER_REQUEST = "GET_OLD_COURIER_REQUEST"
export const GET_OLD_COURIER_REQUEST_SUCCESS = "GET_OLD_COURIER_REQUEST_SUCCESS"

export const COURIER_FINISH_REQUEST = "COURIER_FINISH_REQUEST"
export const COURIER_FINISH_REQUEST_SUCCESS = "COURIER_FINISH_REQUEST_SUCCESS"

export const UPDATE_COURIER_REQUEST = "UPDATE_COURIER_REQUEST"
export const UPDATE_COURIER_REQUEST_SUCCESS = "UPDATE_COURIER_REQUEST_SUCCESS"

export const DELETE_COURIER_REQUEST = "DELETE_COURIER_REQUEST"
export const DELETE_COURIER_REQUEST_SUCCESS = "DELETE_COURIER_REQUEST_SUCCESS"
