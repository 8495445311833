import {
    GET_ALL_INFORMATION_REQUEST_SUCCESS, GET_DASHBOARD_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    info: {
        productsAll: 0,
        productsArrived: 0,
        productsLost: 0,
        productsObtain: 0,
        productsOther: 0,
        productsSent: 0,
        productsStock: 0,
        productsWaiting: 0,
        productsObscure: 0,
        usersAll: 0,
        adminsAll: 0,
        usersNew: 0,
        usersOld: 0,
        flights: 0,
        couriersAll: 0,
        rateUSD: 0,


        transactionArray : [],
        usersFizArray : [],
        usersIurArray : [],
        usersIndArray : [],
    }
}

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                info : action.callBack.info
            }
        case GET_DASHBOARD_REQUEST_SUCCESS:

            return {
                ...state,

                transactionArray : action.callBack.dashboard.transactions,
                usersFizArray : action.callBack.dashboard.usersFiz,
                usersIurArray : action.callBack.dashboard.usersIur,
                usersIndArray : action.callBack.dashboard.usersInd
            }

        default:
            return state
    }
}

export default reducer
