import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import {connect, useDispatch} from "react-redux";
import {getAllInformation} from "../../store/information/actions";

const SidebarContent = props => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  },[]);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = '/dashboard'

    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [props.location.pathname,activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const {info} = props.information

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getAllInformation())
  },[])

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="uil-home-alt"></i>
                <span>მთავარი</span>
              </Link>
            </li>

            <li className="menu-title" style={{fontSize: '16px'}}>გადაზიდვა</li>
            <li>
              <Link to="/logistic/Waiting" className=" waves-effect">
                <i className="uil-clock-eight"></i>
                <span className="badge rounded-pill bg-primary float-end">{info.productsWaiting}</span>
                <span>მოლოდინის რეჟიმში</span>
              </Link>
            </li>

            <li>
              <Link to="/logistic/stock" className=" waves-effect">
                <i className="uil-plane"></i>
                <span className="badge rounded-pill bg-info float-end">{info.productsStock}</span>
                <span>მისულია საწყობში</span>
              </Link>
            </li>
            <li>
              <Link to="/logistic/sent" className=" waves-effect">
                <i className="uil-plane-fly"></i>
                <span className="badge rounded-pill bg-info float-end">{info.productsSent}</span>
                <span>გამოგზავნილი</span>
              </Link>
            </li>

            <li>
              <Link to="/logistic/arrived" className=" waves-effect">
                <i className="uil-plane-arrival"></i>
                <span className="badge rounded-pill bg-info float-end">{info.productsArrived}</span>
                <span>ჩამოსული</span>
              </Link>
            </li>

            <li>
              <Link to="/logistic/obtained" className=" waves-effect">
                <i className="uil-box"></i>
                <span className="badge rounded-pill bg-info float-end">{info.productsObtain}</span>
                <span>გატანილი</span>
              </Link>
            </li>
            <li>
              <Link to="/logistic/obscure" className=" waves-effect">
                <i className="uil-box"></i>
                <span className="badge rounded-pill bg-info float-end">{info.productsObscure}</span>
                <span>გაურკვეველი</span>
              </Link>
            </li>

            <li>
              <Link to="/logistic/giveaway" className=" waves-effect">
                <i className="uil-archive"></i>
                <span>ამანათის გაცემა</span>
              </Link>
            </li>

            <li>
              <Link to="/logistic/sorting" className=" waves-effect">
                <i className="uil-sort-amount-down"></i>
                <span>დახარისხება</span>
              </Link>
            </li>

            <li>
              <Link to="/logistic/sortingNew" className=" waves-effect">
                <i className="uil-sort-amount-down"></i>
                <span>დახარისხება <i style={{color:'#ff9900'}}>NEW</i></span>
              </Link>
            </li>


            <li>
              <Link to="/flight" className=" waves-effect">
                <i className="uil-plane-departure"></i>
                <span className="badge rounded-pill bg-info float-end">{info.flights}</span>
                <span>რეისები</span>
              </Link>
            </li>
            <li className="menu-title" style={{ fontSize: '16px'}}>საკურიერო</li>
            <li>
              <Link to="/courier" className=" waves-effect">
                <span className="badge rounded-pill bg-info float-end">{info.couriersAll}</span>
                <i className="uil-plane-departure"></i>
                <span>საკურიერო მომსახურეიბა</span>
              </Link>
            </li>
            <li>
              <Link to="/courierlist" className=" waves-effect">
                <i className="uil-plane-departure"></i>
                <span>საკურიეროს არქივი</span>
              </Link>
            </li>

            <li className="menu-title" style={{    fontSize: '16px'}}>ტექსტები</li>


            <li>
              <Link to="/news/list" className=" waves-effect">
                <i className="uil-file-bookmark-alt"></i>
                <span>სიახლეები</span>
              </Link>
            </li>

            <li>
              <Link to="/news/create" className=" waves-effect">
                <i className="uil-file-bookmark-alt"></i>
                <span>სიახლის დამატება</span>
              </Link>
            </li>

            <li>
              <Link to="/pages/terms" className=" waves-effect">
                <i className="uil-file-shield-alt"></i>
                <span>წესები</span>
              </Link>
            </li>

            <li>
              <Link to="/notification/create" className=" waves-effect">
                <i className="uil-telegram-alt"></i>
                <span>notification შეტყობინება</span>
              </Link>
            </li>

            <li>
              <Link to="/popap/create" className=" waves-effect">
                <i className="uil-telegram-alt"></i>
                <span>Popup შეტყობინება</span>
              </Link>
            </li>

            <li>
              <Link to="/pages/tariffs" className=" waves-effect">
                <i className="uil-money-bill"></i>
                <span>ტარიფი</span>
              </Link>
            </li>
            <li>
              <Link to="/merchant/transactions" className=" waves-effect">
                <i className="uil-money-bill"></i>
                <span>ტრანზაქციები</span>
              </Link>
            </li>

            <li>
              <Link to="/pages/offices" className=" waves-effect">
                <i className="uil-map-marker-shield"></i>
                <span>ოფისები</span>
              </Link>
            </li>

            <li>
              <Link to="#" className=" waves-effect">
                <i className="uil-map-marker-shield"></i>
                <span>მისამართები</span>
              </Link>
            </li>


            <li className="menu-title" style={{    fontSize: '16px'}}>მომხმარებლები</li>



            <li>
              <Link to="/users" className=" waves-effect">
                <i className="uil-users-alt"></i>
                <span className="badge rounded-pill bg-info float-end">{info.usersAll}</span>
                <span>მომხმარებლები</span>
              </Link>
              <Link to="/admins" className=" waves-effect">
                <i className="uil-users-alt"></i>
                <span className="badge rounded-pill bg-info float-end">{info.adminsAll}</span>
                <span>ადმინები</span>
              </Link>
            </li>
            <li className="menu-title" style={{    fontSize: '16px'}}>პროცესები</li>


            <li>
              <Link to="/logistic/multi" className=" waves-effect">
                <i className="uil-upload"></i>
                <span>მულტი პროცესები</span>
              </Link>
            </li>

            <li className="menu-title" style={{    fontSize: '16px'}}>სოკეტი</li>

            <li>
              <Link to="/" className=" waves-effect">
                <i className="uil-processor"></i>
                <span>რაღაცა</span>
              </Link>
            </li>


          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
const mapStateToProps = state => {
  const {
    information
  } = state
  return {
    information
  }
};

export default withRouter(connect(mapStateToProps)(SidebarContent))
