import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, FormGroup, InputGroup, Label, Row,} from "reactstrap";
import ReactExport from "react-export-excel";


import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {courierFinish, getCouriers, getOldCouriers, getUsers} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CourierList = props => {
    const dispatch = useDispatch();
    const [date, setDate] = useState('')
    const [skip, setSkip] = useState(0)
    const [type, setType] = useState('all')
    const [limit, setLimit] = useState(100)
    const [branches, setBranches] = useState('all')


    const handleSearch = () => {
        let item = {
            branch: branches,
            date: moment(date[0]).format("YYYY-MM-DD"),
            type: type,
            skip: skip,
            limit: limit,
        }
        dispatch(getOldCouriers(item))
    }



/*    useEffect(() => {
        let item = {
            branch : branches,
            type: type,
            skip: skip,
            limit: limit,
        }
        dispatch(getCouriers(item))
    }, [branches])*/

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="საკურიერო"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <InputGroup>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="DD-MM-YYYY"
                                                value={date}
                                                onChange={(date) => setDate(date)}
                                                options={{
                                                    altInput: true,
                                                    dateFormat: "YYYY-MM-DD",
                                                    altFormat: "DD-MM-YYYY",
                                                    allowInput: true,
                                                    parseDate: (datestr, format) => {
                                                        return moment(datestr, format, true).toDate();
                                                    },
                                                    formatDate: (date, format, locale) => {
                                                        // locale can also be used
                                                        return moment(date).format(format);
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="validationCustom02">ფილიალი</Label>
                                        <select
                                            value={branches}
                                            onChange={(e) => setBranches(e.target.value)}
                                            name="branch"
                                            className="form-control">
                                            <option value="all">ყველა</option>
                                            <option value="didube" >დიდუბე</option>
                                            <option value="gldani" >გლდანი</option>
                                            <option value="isani" >ისანი</option>
                                            <option value="kutaisi_1" >ქუთაისი 1</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <div className="button-items">
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn btn-success w-lg waves-effect waves-light"
                                                // disabled={props.logistic.preloaderAll}
                                                onClick={handleSearch}
                                            >
                                                <span> ძებნა</span>
                                            </Button>{" "}
                                        </div>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                page='index'
                                array={props.Courier.array}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        Courier,
    } = state
    return {
        Courier
    }
};
export default withRouter(connect(mapStateToProps)(CourierList));
