import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import logo from "../../assets/images/logo.png";
import {Col, Row, Table} from "reactstrap";
import Moment from "react-moment";
import {isEmpty} from "lodash";
const List = (props) =>{

    return(
        <React.Fragment key={props.key}>
            <div style={{padding: '10px'}}></div>
            <div className="invoice-title">
                <h4 className="float-end font-size-16">

                </h4>
                <div className="mb-4">
                    <img src={logo} alt="logo" height="60"/>
                </div>
            </div>
            <hr className="my-4"/>
            <Row>
                <Col sm="6">
                    <div className="text-muted">
                        <p className="mb-1">შპს ალიჯორჯია:</p>
                        <p className="mb-1">ს/კ: 402011568</p>
                        <p className="mb-1">ფაქტობრივი მისამართი</p>
                        <p className="mb-1">ქ. თბილისი არჩილ ქურდიანის
                            12/14</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="text-muted text-sm-end">
                        <div>

                            <p className="mb-1">თარიღი: <span>
                                {
                                    !isEmpty(props.item.invoice)
                                    ?
                                        <>
                                            {
                                                !isEmpty(props.item.invoice.createdAt)
                                                    ? <Moment format="YYYY-MM-DD" date={props.item.invoice.createdAt} />
                                                    :null
                                            }
                                        </>
                                        : null
                                }


                            </span>
                            </p>

                        </div>
                        <div>
                            {
                                !isEmpty(props.item.invoice)
                                    ?
                                    <>
                                        {
                                            !isEmpty(props.item.invoice.invoiceUnicId)
                                                ?    <p className="mb-1">ნომერი: <span>{props.item.invoice.invoiceUnicId}</span>  </p>
                                                :null
                                        }
                                    </>
                                    : null
                            }




                        </div>

                    </div>
                </Col>
            </Row>
            <hr className="my-4"/>
            <Row>
                <Col>
                    <div className="text-muted">
                        <p className="mb-1">სს. "საქართველოს ბანკი"</p>
                        <p className="mb-1">ანგ: №
                            GE75BG0000000917631100</p>
                        <p className="mb-1">ბანკის კოდი 220101502 /
                            BAGAGE22 /</p>
                        <p className="mb-1">დანიშნულება</p>
                        <p>ტრანსპორტირების საფასური</p>
                    </div>
                </Col>
            </Row>
            <hr className="my-4"/>
            <Row>
                <Col>
                    <div className="text-muted">
                        <h5 className="font-size-15">
                            <strong>მიმღები</strong></h5>
                    <p className="mb-1">
                            {
                                parseInt(props.item.additionalInformation.userType) === 1  || isEmpty( props.item.additionalInformation.userType )
                                ? <> სახელი, გვარი:
                                        {
                                            props.item.additionalInformation.nonGeorgianCitizen
                                            ?<span>{props.item.additionalInformation.nameEn} {' '} {props.item.additionalInformation.lastNameEn}</span>
                                            :<span>{props.item.additionalInformation.nameKa} {' '} {props.item.additionalInformation.lastNameKa}</span>

                                        }

                                    </>
                                   :null
                            }

                            {
                                parseInt(props.item.additionalInformation.userType)=== 2
                                    ? <> კომპანის დასახელება: <span>{props.item.additionalInformation.LTDName} </span> </>
                                    :null
                            }

                        </p>
                        <p className="mb-1">
                            {
                                parseInt(props.item.additionalInformation.userType) === 1  || isEmpty( props.item.additionalInformation.userType )
                                    ? <>  პირადი  ნომერი: <span>{props.item.additionalInformation.passport}</span> </>
                                    :null
                            }

                            {
                                parseInt(props.item.additionalInformation.userType) === 2
                                    ? <> კომპანის ნომერი: <span>{props.item.additionalInformation.orgId} </span> </>
                                    :null
                            }



                        </p>

                    </div>
                </Col>
            </Row>
            <hr className="my-4"/>
            <br/>
            <div className="table-responsive">
                <Table className="table-nowrap table-centered mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "70px"}}>გზავნილის ნომერი
                        </th>
                        <th>რაოდენობა</th>
                        <th>წონა (კგ)</th>
                        <th>ტარიფი (USD)</th>
                        <th>ეროვ. ბანკის კურსი</th>
                        <th>თანხა (ლ.)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.item.products.map((it, k) => {
                            return (
                                <React.Fragment key={k}>
                                    <tr key={k.key}>
                                        <td>{it.productOrderId}</td>
                                        <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                        <td style={{borderLeft: '1px solid #f5f6f8'}}>{it.weight}</td>
                                        <td style={{borderLeft: '1px solid #f5f6f8'}}>{it.tariff}</td>


                                        {
                                            !isEmpty(it.rate)
                                                ?
                                                <>
                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                        {
                                                            !isEmpty(it.rate)
                                                                ? it.rate.USD
                                                                : null
                                                        }
                                                    </td>
                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                                        {(it.weight * it.tariff * it.rate.USD).toFixed(2)}</td>
                                                </>
                                                :
                                                <>
                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                    <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                                                </>
                                        }
                                    </tr>
                                </React.Fragment>
                            )
                        })
                    }
                    <tr>
                        <td><strong>სულ</strong></td>
                        <td style={{borderLeft: '1px solid #f5f6f8'}}>
                            <strong>{props.item.additionalInformation.allQuantity}</strong>
                        </td>
                        <td style={{borderLeft: '1px solid #f5f6f8'}}>
                            <strong>{props.item.additionalInformation.allWeight.toFixed(2)}</strong>
                        </td>
                        <td style={{borderLeft: '1px solid #f5f6f8'}}>{}</td>
                        <td style={{borderLeft: '1px solid #f5f6f8'}}>{}</td>
                        {
                            !isEmpty(props.item.additionalInformation.allSum) || props.item.additionalInformation.allSum > 0
                                ?
                                <td style={{borderLeft: '1px solid #f5f6f8'}}>
                                    <strong>{props.item.additionalInformation.allSum.toFixed(2)} </strong>
                                </td>
                                :
                                <td style={{borderLeft: '1px solid #f5f6f8'}}></td>
                        }
                    </tr>

                    </tbody>
                </Table>
            </div>
            <hr className="my-4"/>
            <Row>
                <Col>
                    <div className="text-muted">
                        <h5 className="font-size-15">
                            <strong>კონტაქტი:</strong></h5>
                        <p className="mb-1">ვებ
                            გვერდი: <span>www.aligeorgia.ge</span>
                        </p>
                        <p className="mb-1">ელ.ფოსტა: <span>info.aligeorgia@gmail.com</span>
                        </p>
                        <p className="mb-1">ტელეფონი: <span>(+032) 2 196 191</span>
                        </p>
                        <p className="mb-1">გმადლობთ, რომ სარგებლობთ
                            ჩვენი
                            მომსახურებით!</p>
                    </div>
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default List
